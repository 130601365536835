import React, { Component } from "react";
import {
  Container,
  Segment,
  Dimmer,
  Loader,
  Divider,
  Button,
  Icon,
  Header,
  Transition,
  Modal,
  Image,
  Grid,
  Form,
  TextArea,
  Message,
  Radio,
  Popup,
  Select,
  Input,
  Label,
} from "semantic-ui-react";

import { Helmet } from "react-helmet";

import luckoryLogo from "../imgs/logo7.png";

// import deliveryIcon from "../imgs/delivery.png";
// import pickupIcon from "../imgs/pickup.png";

import deliveryIcon from "../imgs/delivery1.png";
import pickupIcon from "../imgs/pickup1.png";

import noticeBgImg from "../imgs/noticeBg.svg";

//---------------- for halloween --------------------------
// import homeIcon from "../imgs/halloween/castle.png";
// import timeIcon from "../imgs/halloween/calendar.svg";
// import phoneIcon from "../imgs/halloween/mail.svg";

import MenuDb from "./../dataModel/menuDb";
import BusinessInfoDb from "./../dataModel/businessInfoDb";
// import UserDb from "./../dataModel/userDb";
import ShoppingCartDb from "./../dataModel/shoppingCartDb";
// import CardPointeDb from "./../dataModel/cardPointeDb";
import ECommDb from "./../dataModel/ecommDb";

import ItemListPanel from "./itemListPanel";
import CategoryListPanel from "./categoryListPanel";
import CategoryListScrollbar from "./categoryListScrollbar";
import CategoryListScrollbarModal from "./categoryListScrollbarModal";
import ShoppingCartPanel from "./shoppingCartPanel";
import OrderSummaryPanel from "./orderSummaryPanel";
import {
  countOrderItems,
  capitalFirst,
  calSubTotal,
  formatAddress,
  formatAddressWithoutCountryName,
  getBusinessHourStr,
  checkIfWithinBusinessHour,
  formatPhoneNumber,
  verifyCustomerPhone,
  verifyCardNumber,
  verifyCreditCardCVC,
  verifyCreditCardExpiration,
  verifyUSPostalCode,
  isSmallDevice,
  checkIfWithinBusinessHour2,
  isLargeDevice,
  toUrl,
  getFoodPreparingTimeObj,
  addPromoMethod,
  groupOrderedItemsByCategory,
  checkIfWithinDeliveryHour,
  getTimeStringFromMin,
  getTimeScheduleList,
  getXDayBusinessHour,
  formatDateMonthDayYear,
  secondsToTimeStr,
  checkIfWithinOrderPromotionHour,
  formatDateMonthDay,
} from "./../dataModel/utils";

import GoogleMapPanel from "./googleMapPanel";
import { getDistanceInMiles, getGoogleDistance } from "../dataModel/utils";

// import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import "../css/auth.css";
import UserAuthModal from "./userAuthModal";
import UserDashboardModal from "./userDashboardModal";
import AuthDb from "../dataModel/authDb";
import VerificationDb from "../dataModel/verificationDb";

const googleMapKey = "AIzaSyCi5MqSwmmuTW1R-Axt3dWOabhZUx386tg";
Geocode.setApiKey(googleMapKey);

// -=--------------------------------------------------------------------------

class HomePanelPanel extends Component {
  STEPS = {
    ORDERING: 0,
    CHECKINGOUT: 1,
  };

  METHOD = {
    PICKUP: 0,
    DELIVERY: 1,
    CURBSIDE: 2,
    DINEIN: 3,
    ROOM_SERVICE_DELIVERY: 4,
  };

  PAYMENT_METHOD = {
    CREDITCARD: 0,
    CASH: 1,
  };

  state = {
    loading: false,
    loadingMsg: null,
    loadingItems: true,
    categories: null,
    category_selected: null,

    items: null, // displayed items not in use, use itemsGroupbyCategory instead
    itemsGroupbyCategory: null,

    item_selected: null,
    itemOptions: null, // displayed itemOptions
    itemSizes: null, // displayed itemSizes
    toogleList: {},

    showBusinessNotice: true,
    businessInfo: null, // { name, address:{street, city, state, postalCode}, phone, email, type, webSite }
    withinBusinessHour: true,
    withinBusinessHour_today: true,
    store_lat: null,
    store_lng: null,

    // itemOptionVals_selected: null,
    itemSize_selected: null,

    itemOption_selected: null, // key is _idOption, value is selected [itemOptionValue, ...]

    itemQty: 1,
    itemNote: "",

    // ---------------------- category srolling bar -------------------
    showLeftScrollingSign: false,
    showRightScrollingSign: true,

    // ---------------------- shopping cart info ----------------------
    orderedItems: [],
    // orderedItems_PromoMethod: [],
    order_tips_pct: 0.1,
    fixedTipRate: null,
    custom_tips: null, // String
    tax_rate: null,

    promoCode: null,
    promoCodeApplied: null,

    // ---------------------- order Summery panel --------------------------
    orderSummaryErrorMsgShow: false,
    orderSummaryErrorMsg_head: null,
    orderSummaryErrorMsg_body: null,

    orderMethod: this.METHOD.PICKUP,
    dineinTableName: null,

    carMakeModel: null,
    carColor: null,

    receiveOrderInfo: true, // receive SMS

    customerAddressStr: null,
    customerPostalCode: null,
    deliveryNote: null,
    lat: null,
    lng: null,
    customerPhone: null,
    customerName: null,
    customerEmail: null,
    customerAddr_lat: null,
    customerAddr_lng: null,

    cardNumber: null,
    cardCVV: null, // "123"
    cardExpiration: null, // MMYY

    needCustomerAddrMsgShow: false,
    needCustomerInfoMsgShow: false, // name or phone
    needCardInfoMsgShow: false,
    needCarInfoMsgShow: false,
    notAboveMinOrderForDeliveryShow: false,

    paymentMethod: null,
    billingAddressStr: null,
    billingZipCode: null,

    scheduleTimeSelected: false, // false asap, true schedule
    scheduleTimeStr: null,

    scheduleDate: null,

    //-------------------------------------------------------------------
    curStep: this.STEPS.ORDERING,

    // user: null, // {uid:String}

    showShoppingCart: false, // only used in small screen
    showCategoryListModal: false, // only used in small screen

    // --------------------- delivery/pickup select, address entered if delivery ---------------------
    showOrderMethodSelectModal: true,
    showDeliveryAddrModal: false,
    loading_googleMap: false,
    deliveryOutRangeWarning: false,
    deliveryFee: null,
    deliveryFees: null,
    deliveryRangeInMile: null,

    roomServiceDeliveryFee: null,

    // -------------------------- order promotion ------------------------------------
    showOrderPromotionModal: false,
    freeItemNameSelected: null,

    // ----------------------- room service ---------------------------
    roomServiceRoom: null,

    // -------------- for branch store ----------------------
    businessName: null,
    branchName: null,
    businessAddress: null,
    branchAddress: null,
    businessInfoAddressSelected: null,
    businessNameSelected: null,
    isBranchSelected: false,
    showBusinessInfoAddressSelectModal: false,

    // -------------------- user auth & dashboard ---------------------
    showUserAuth: false,
    showUserDashboard: false,

    showScrollModal: false,

    scheduledTimeSelectOpen: false,
    timeSchedualList: [],
    xDayBusinessHours: [],

    phoneVerificationModalShow: false,
    phoneVerificationErrorMsgShow: false,
    phoneVerificationErrorMsg: "",
    phoneVerificationCodeInputShow: false,
    phoneVerificationCodeLoading: false,
    verificationCodeEntered: "",
  };

  componentDidMount = async () => {
    this.startLoading();

    // ---------------------- fetch businessInfo based on merchantId -------------------------
    const merchantId = this.props.merchantId;
    let storeName = this.props.storeName;

    const businessInfo =
      merchantId && (await BusinessInfoDb.getBusinessInfo(merchantId));

    if (!businessInfo) return toUrl("/");
    const businessName = (businessInfo.name || "").replace(/\s/gi, "-");

    if (!storeName || storeName !== businessName) {
      // return toUrl(`/store/${merchantId}/${businessName}`);
    }

    const supportCreditcardPayment = businessInfo.supportCreditcardPayment;
    const supportCashPayment = businessInfo.supportCashPayment;

    const paymentMethod = supportCreditcardPayment
      ? this.PAYMENT_METHOD.CREDITCARD
      : supportCashPayment
      ? this.PAYMENT_METHOD.CASH
      : null;

    window &&
      window.addEventListener("resize", () => {
        if (isLargeDevice()) {
          !this.state.showShoppingCart &&
            this.setState({
              showShoppingCart: true,
            });
        }

        if (isSmallDevice() && this.state.curStep === this.STEPS.CHECKINGOUT) {
          this.setState({
            showShoppingCart: false,
          });
        }
      });

    //-------------------- dinein table ----------------------
    const tablename = this.props.tablename;

    if (tablename) {
      this.setState({
        orderMethod: this.METHOD.DINEIN,
        dineinTableName: tablename,
      });
    }

    // ------------------------------ init category and items -----------------------------

    const categories = await MenuDb.getCategories(merchantId);
    // ---- sort categories, put current unavailable categories in the end of display list

    (categories || [])
      // .filter(
      //   ({ unavailableForBranch }) =>
      //     !unavailableForBranch || !this.state.isBranchSelected
      // )
      .sort((cate1, cate2) => {
        if (!cate1 && !cate2) return 0;
        if (!cate1) return 1;
        if (!cate2) return -1;

        const availableHour1 = cate1.availableHour;
        const availableHour2 = cate2.availableHour;

        const ifAvailable1 = checkIfWithinBusinessHour2(availableHour1);
        const ifAvailable2 = checkIfWithinBusinessHour2(availableHour2);

        if ((ifAvailable1 && ifAvailable2) || (!ifAvailable1 && !ifAvailable2))
          return 0;
        if (!ifAvailable1) return 1;
        else return -1;
      });

    const partItems = await MenuDb.getItems(merchantId, 10);
    // console.log(123, partItems);

    // const allItems = await MenuDb.getItems(merchantId);

    // partItemsGroupbyCategory = [ {category:{_id, name, name_2nd}, items:[item, item,..]}, ... ]
    const partItemsGroupbyCategory = (categories || []).map((category) => {
      const items = (partItems || []).filter(
        (item) => item._idCategory === category._id
      );

      return { category, items };
    });

    const withinBusinessHour = checkIfWithinBusinessHour(
      businessInfo && businessInfo.businessHour
    );

    let lat, lng;

    const location = await this.fetchBusinessLocation(businessInfo);
    if (!location) {
      console.error("Internal Error");
    } else {
      lat = location.lat;
      lng = location.lng;
    }

    // ------------------- read orderItems from cookie ------------------

    const orderItems = await ShoppingCartDb.getOrderItems(merchantId);
    // ---------------------- check if merchant provide delivery service --------------
    const ifDelivery = businessInfo && !!businessInfo.delivery;
    // const maxDistance = businessInfo && businessInfo.deliveryRangeInMile;
    // const deliveryFee = businessInfo && businessInfo.deliveryFee;
    const deliveryFees = businessInfo && businessInfo.deliveryFees;
    const deliveryAvailableTime =
      businessInfo && businessInfo.deliveryAvailableTime;

    // ------------------------- orderPromotionAvailableTime -------------------------

    const minOrderForDelivery =
      businessInfo && businessInfo.minOrderForDelivery;

    // ----------------------- food preparing time ----------------------------------
    const foodPreparingTimeObj =
      businessInfo && getFoodPreparingTimeObj(businessInfo.foodPreparingTime);

    // ------------------------- promoMethod ----------------------------------

    // const orderItems_expanded = this.expandOrderItemsIfPromoMethod(
    //   orderItems,
    //   categories
    // );

    // const orderedItems_PromoMethod = await this.getOrderedItemsAfterPromoMethod(
    //   orderItems_expanded
    // );

    // ----------- branch check --------------
    const enableBranch = businessInfo && !!businessInfo.enableBranch;
    const branchAddress = businessInfo && businessInfo.branchAddress;
    if (
      enableBranch &&
      branchAddress &&
      !this.state.businessInfoAddressSelected
    ) {
      this.setState({
        businessAddress: businessInfo && businessInfo.address,
        branchAddress: branchAddress,
        businessName: businessInfo && businessInfo.name,
        branchName: businessInfo && businessInfo.branchName,
        showBusinessInfoAddressSelectModal: true,
      });
    } else {
      this.setState({
        businessInfoAddressSelected: businessInfo && businessInfo.address,
        businessNameSelected: businessInfo && businessInfo.name,
      });
    }

    const config = {
      year: "numeric",
      month: "short",
      day: "2-digit",
    };
    const dateTimeFormat = new Intl.DateTimeFormat("default", config);

    this.endLoading();

    this.setState({
      itemsGroupbyCategory: partItemsGroupbyCategory,
      items: partItems,
      categories: categories,
      category_selected: (categories || [])[0],
      businessInfo: businessInfo,
      orderedItems: orderItems, // orderedItems: orderItems_expanded,
      // orderedItems_PromoMethod: orderedItems_PromoMethod,
      showShoppingCart: isLargeDevice(),
      // lat: !ifDelivery ? lat : null,
      // lng: !ifDelivery ? lng : null,
      lat: lat,
      lng: lng,
      store_lat: lat,
      store_lng: lng,
      withinBusinessHour,
      withinBusinessHour_today: withinBusinessHour,
      merchantId,
      tax_rate:
        businessInfo && businessInfo.taxRate != null
          ? Number(businessInfo.taxRate)
          : 0.07,
      fixedTipRate:
        businessInfo && businessInfo.fixedTipRate != null
          ? Number(businessInfo.fixedTipRate)
          : 0,
      roomServiceDeliveryFee:
        businessInfo && businessInfo.roomServiceDeliveryFee != null
          ? Number(businessInfo.roomServiceDeliveryFee)
          : 0,
      paymentMethod,
      showOrderMethodSelectModal: ifDelivery && !tablename,
      provideDelivery: ifDelivery,
      minOrderForDelivery: minOrderForDelivery,

      // deliveryRangeInMile: maxDistance,
      // deliveryFee,
      deliveryFees,
      deliveryAvailableTime,
      foodPreparingTimeObj,
      // scheduleDate: dateTimeFormat.format(new Date()),

      scheduleDate: null,
    });

    setTimeout(() => {
      this.setState({
        showBusinessNotice: false,
      });
    }, 30000);
    // await this.handlePickupMethodClick();
    // initialize order method to pickup

    setTimeout(() => {
      this.loadingItems(merchantId, categories);
    }, 0);
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.userInfo !== this.props.userInfo &&
      Object.keys(this.props.userInfo).length > 0
    ) {
      this.setState({
        billingAddressStr: this.props.userInfo.order_address,
        customerPhone: this.props.userInfo.order_phone,
        customerName: this.props.userInfo.order_name,
        billingZipCode: this.props.userInfo.order_zipcode,
      });
    }
  };

  _autoAppliedPromoCodeApplyDetect = async (merchantId) => {
    if (!merchantId) return;

    const { orderedItems } = this.state;

    const subtotal = calSubTotal(orderedItems || []);
    // console.log(1111111, subtotal);
    if (!subtotal) {
      return this.setState({
        promoCodeApplied: null,
      });
    }

    const promoCode = await BusinessInfoDb.getAutoAppliedPromoCode(
      merchantId,
      subtotal
    );
    // console.log(22222222, promoCode);

    this.setState({
      promoCodeApplied: promoCode && promoCode.percentageOff ? promoCode : null,
    });
  };

  loadingItems = async (merchantId, categories) => {
    const allItems = await MenuDb.getItems(merchantId);

    // itemsGroupbyCategory = [ {category:{_id, name, name_2nd}, items:[item, item,..]}, ... ]
    const itemsGroupbyCategory = (categories || []).map((category) => {
      const items = (allItems || [])
        .filter((item) => item._idCategory === category._id)
        .sort((item1, item2) => {
          try {
            if (item1.item_order_id < item2.item_order_id) return -1;
            if (item1.item_order_id > item2.item_order_id) return 1;
            return 0;
          } catch (ex) {
            return 0;
          }
        });

      return { category, items };
    });

    // console.log(234, allItems.length);

    this.setState({
      itemsGroupbyCategory,
      items: allItems,
      // category_selected: (categories || [])[0],
      loadingItems: false,
    });
  };

  // loading more items from database when scroll to bottom
  initItemLoadingEvent = () => {
    window.onscroll = function (ev) {
      if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
        // you're at the bottom of the page
        console.log("Bottom of page");
      }
    };
  };

  fetchBusinessLocation = async (businessInfo) => {
    if (!businessInfo) return null;

    let lat, lng;
    if (businessInfo.location) {
      lat = businessInfo.location.lat;
      lng = businessInfo.location.lng;
      console.log("no calling google api");
    } else if (businessInfo.address) {
      // using google map api
      const addressStr = formatAddress(businessInfo.address);
      const response = addressStr && (await Geocode.fromAddress(addressStr));
      const location = response && response.results[0].geometry.location;
      lat = (location || {}).lat;
      lng = (location || {}).lng;
      console.log(
        "No corrdinates set up for this merchant. Calling Google Map Api"
      );
    }
    return {
      lat,
      lng,
    };
  };

  startLoading = (msg) => {
    this.setState({
      loading: true,
      loadingMsg: msg,
    });
  };

  endLoading = () => {
    this.setState({
      loading: false,
      loadingMsg: null,
    });
  };

  moveStepTo = (step) => {
    if (this.state.curStep !== step) {
      this.setState({
        curStep: step,
      });
    }
  };

  showOrderSummaryErrorMsg = (msgHead, msgBody, duration) => {
    this.setState({
      orderSummaryErrorMsg_head: msgHead,
      orderSummaryErrorMsg_body: msgBody,
      orderSummaryErrorMsgShow: true,
    });

    if (duration && duration > 0) {
      setTimeout(() => {
        this.setState({
          // orderSummaryErrorMsg_head: null,
          // orderSummaryErrorMsg_body: null,
          orderSummaryErrorMsgShow: false,
        });
      }, duration);
    }
  };

  hideOrderSummaryErrorMsg = () => {
    this.setState({
      // orderSummaryErrorMsg_head: null,
      // orderSummaryErrorMsg_body: null,
      orderSummaryErrorMsgShow: false,
    });
  };

  handleLuckoryLogoClick = () => {
    // toUrl("https://www.luckory.com/");
  };

  // category is null, select all
  handleCategoryChange = async (category) => {
    return this.setState({
      category_selected: category,
      item_selected: null,
      itemOption_selected: null,
      itemQty: 1,
      itemNote: "",
      showScrollModal: false,
    });
  };

  handleCategoryModalClick = () => {
    this.setState({ showScrollModal: true });
  };

  handleCategoryListScroll = (evt) => {
    const { scrollLeft, scrollWidth, clientWidth } = evt.target;
    const hitLeftEnd = scrollLeft === 0 ? true : false;
    const hitRightEnd =
      scrollWidth - scrollLeft - clientWidth === 0 ? true : false;

    // showLeftScrollingSign, showRightScrollingSign
    const showLeftScrollingSign = !hitLeftEnd;
    const showRightScrollingSign = !hitRightEnd;

    if (
      this.state.showLeftScrollingSign !== showLeftScrollingSign ||
      this.state.showRightScrollingSign !== showRightScrollingSign
    ) {
      this.setState({
        showLeftScrollingSign,
        showRightScrollingSign,
      });
    }
  };

  handleItemClick = async (item) => {
    if (!item) return console.error("Null item provided");

    this.startLoading();
    const itemOptions = await MenuDb.getItemOptionsById(
      this.state.merchantId,
      item._id
    );
    const itemSizes = await MenuDb.getItemSizesById(
      this.state.merchantId,
      item._id
    );
    let toogleList = {};
    for (let option in itemOptions) {
      toogleList[itemOptions[option]._id] = false;
    }
    // console.log(123, itemSizes);

    this.endLoading();

    this.setState({
      item_selected: item,
      itemOptions: itemOptions,
      toogleList: toogleList,
      itemSizes: itemSizes,
      // itemOptionVals_selected: null,
      itemOption_selected: null,
      itemSize_selected: null,
      itemQty: 1,
      itemNote: "",
    });
  };

  handleItemModalClose = () => {
    this.setState({
      item_selected: null,
      itemOptions: null,
      toogleList: {},
      itemSizes: null,
      // itemOptionVals_selected: null,
      itemOption_selected: null,
      itemSize_selected: null,
      itemQty: 1,
      itemNote: "",
    });
  };

  handleItemQtyChange = (qty) => {
    this.setState({ itemQty: qty });
  };

  handleItemNoteChange = (note) => {
    const note200 = (note || "").substring(0, 200);
    this.setState({ itemNote: capitalFirst(note200) });
  };

  handleItemOptionChange = (
    checked,
    optionValue,
    _idItemOption,
    numberToSelect,
    minNumberToSelect,
    maxNumberToSelect
  ) => {
    // const itemOptionVals_selected = this.state.itemOptionVals_selected || []; // old

    const itemOption_selected = { ...(this.state.itemOption_selected || {}) };

    const itemOptionVals = (itemOption_selected || {})[_idItemOption] || [];
    if (checked) {
      if (itemOptionVals.length >= maxNumberToSelect) {
        itemOptionVals.shift();
      }
      itemOptionVals.push(optionValue);

      // if (numberToSelect !== 0 && itemOptionVals.length >= numberToSelect) {
      //   itemOptionVals.shift();
      // }

      // itemOptionVals.push(optionValue);
    } else {
      let index = itemOptionVals.findIndex(
        (itemOptionVal) => itemOptionVal._id === optionValue._id
      );

      while (index > -1) {
        itemOptionVals.splice(index, 1);
        index = itemOptionVals.findIndex(
          (itemOptionVal) => itemOptionVal._id === optionValue._id
        );
      }
    }

    itemOption_selected[_idItemOption] = itemOptionVals;

    this.setState({
      //itemOptionVals_selected: itemOptionVals_selected,
      itemOption_selected: itemOption_selected,
    });
  };

  handleItemOptionDecreaseClick = (
    optionValue,
    _idItemOption,
    numberToSelect,
    minNumberToSelect,
    maxNumberToSelect
  ) => {
    // console.log("Decrease click");

    const itemOption_selected = { ...(this.state.itemOption_selected || {}) };

    const itemOptionVals = (itemOption_selected || {})[_idItemOption] || [];

    const index = itemOptionVals.findIndex(
      (itemOptionVal) => itemOptionVal._id === optionValue._id
    );
    if (index > -1) {
      itemOptionVals.splice(index, 1);
    }

    itemOption_selected[_idItemOption] = itemOptionVals;

    this.setState({
      //itemOptionVals_selected: itemOptionVals_selected,
      itemOption_selected: itemOption_selected,
    });
  };

  handleItemOptionIncreaseClick = (
    optionValue,
    _idItemOption,
    numberToSelect,
    minNumberToSelect,
    maxNumberToSelect
  ) => {
    const itemOption_selected = { ...(this.state.itemOption_selected || {}) };

    const itemOptionVals = (itemOption_selected || {})[_idItemOption] || [];
    // if (numberToSelect !== 0 && itemOptionVals.length >= numberToSelect) {
    if (itemOptionVals.length >= maxNumberToSelect) {
      const index = (itemOptionVals || []).findIndex(
        (itemOptionVal) => itemOptionVal._id != optionValue._id
      );
      if (index > -1) {
        itemOptionVals.splice(index, 1);
      } else {
        itemOptionVals.shift();
      }
    }

    itemOptionVals.push(optionValue);

    itemOption_selected[_idItemOption] = itemOptionVals;

    this.setState({
      itemOption_selected: itemOption_selected,
    });
  };

  handleItemSizeChange = (checked, itemSize) => {
    if (checked) {
      this.setState({
        itemSize_selected: itemSize,
      });
    }
  };

  handleToggleListClick = (optionId) => {
    let toogleList = { ...this.state.toogleList };
    toogleList[optionId] = !toogleList[optionId];
    this.setState({ toogleList });
  };

  /*
    OLD:
    orderedItem:{
      item: item, // { name, price, ... }
      qty:Number,
      addedOptionVals: [ optionValue, ... ], // optionValue: {name, price, _idItemOption}
      note: String
    }

    NEW:
    orderedItem{
      name,String,
      qty:Number,
      price:Number,
      orderItemOptions: [itemOptionValueSchema],
      orderItemModifys: [orderItemModifySchema],
      size:String,
      discount: discountObjSchema,
      note: String
    }
  */
  handleAddToCartClick = async (orderedItem) => {
    try {
      const orderedItems = [...(this.state.orderedItems || [])];
      orderedItems.push(orderedItem);

      //console.log(8, orderedItem);

      // --------------------  cookie -----------------------------
      this.startLoading();
      if (orderedItems && orderedItems.length > 0) {
        await ShoppingCartDb.updateOrderItems(
          this.state.merchantId,
          orderedItems
        );
      }

      const orderItems_expanded = this.expandOrderItemsIfPromoMethod(
        orderedItems,
        this.state.categories
      );

      this.setState({
        orderedItems: orderedItems,
        // orderedItems_PromoMethod: orderedItems_PromoMethod,
        item_selected: null,
        toogleList: {},
        itemOptions: null, // displayed itemOptions

        // itemOptionVals_selected: null,
        itemOption_selected: null,
        itemQty: 1,
        itemNote: "",
      });
    } finally {
      setTimeout(async () => {
        await this._autoAppliedPromoCodeApplyDetect(
          this.state.businessInfo && this.state.businessInfo.merchantId
        );
      }, 0);
      this.endLoading();
    }
  };

  handleRemoveFromCartClick = async (orderedIndex) => {
    try {
      if (orderedIndex === null)
        return console.error("Null orderedIndex provided");

      this.startLoading();

      if (orderedIndex > -1) {
        const orderedItems = [...(this.state.orderedItems || [])];
        orderedItems.splice(orderedIndex, 1);

        // --------------------  cookie -----------------------------
        //this.startLoading();
        await ShoppingCartDb.updateOrderItems(
          this.state.merchantId,
          orderedItems
        );
        //this.endLoading();
        // const orderedItems_PromoMethod = await this.getOrderedItemsAfterPromoMethod(
        //   orderedItems
        // );

        this.setState({
          orderedItems,
          // orderedItems_PromoMethod: orderedItems_PromoMethod,
        });

        // ---------- initial free addon items if available -----------
        const orderPaymentObject = await this.getOrderPaymentObject();
        const { businessInfo } = this.state;
        const inOrderPromotionAvailableTime = !!(
          businessInfo &&
          businessInfo.orderPromotionsAvailableTime &&
          checkIfWithinOrderPromotionHour(
            businessInfo.orderPromotionsAvailableTime
          )
        );

        if (
          businessInfo &&
          businessInfo.enableOrderPromotion &&
          inOrderPromotionAvailableTime &&
          businessInfo.orderPromotions
        ) {
          const orderPromotions = businessInfo.orderPromotions;
          orderPromotions.sort((obj1, obj2) => {
            if (obj1.amount < obj2.amount) return 1;
            else if (obj1.amount > obj2.amount) return -1;
            return 0;
          });

          let freeItemNames_selected = null;

          for (let index = 0; index < orderPromotions.length; index++) {
            const { amount, freeItemNames } = orderPromotions[index];
            if (
              orderPaymentObject &&
              orderPaymentObject.subtotal != null &&
              orderPaymentObject.subtotal >= amount
            ) {
              freeItemNames_selected = freeItemNames;

              return this.setState({
                freeItemNames_selected,
                amount_selected: amount,
                freeItemNameSelected:
                  amount === this.state.amount_selected
                    ? this.state.freeItemNameSelected
                    : null,
                // showOrderPromotionModal: true,
              });
            }
          }
        }

        this.setState({
          freeItemNames_selected: null,
          amount_selected: null,
          freeItemNameSelected: null,
          // showOrderPromotionModal: true,
        });
      }
    } finally {
      setTimeout(async () => {
        await this._autoAppliedPromoCodeApplyDetect(
          this.state.businessInfo && this.state.businessInfo.merchantId
        );
      }, 0);
      this.endLoading();
    }
  };

  handleRemoveUnavailableItems = async () => {
    const orderedItems = (this.state.orderedItems || []).filter(
      ({ availableHour }) => {
        return (
          !availableHour ||
          checkIfWithinBusinessHour2(availableHour, this.state.scheduleDate)
        );
      }
    );

    // --------------------  cookie -----------------------------
    //this.startLoading();
    await ShoppingCartDb.updateOrderItems(this.state.merchantId, orderedItems);
    //this.endLoading();

    this.setState({
      orderedItems,
    });
  };

  handleCheckoutClick = async () => {
    try {
      this.startLoading();

      const hasUnavailableItem = (this.state.orderedItems || []).some(
        ({ availableHour }) => {
          return (
            availableHour &&
            !checkIfWithinBusinessHour2(availableHour, this.state.scheduleDate)
          );
        }
      );
      if (hasUnavailableItem) {
        this.showOrderSummaryErrorMsg(
          "Please remove unavailable item(s) to continue.",
          null,
          5000
        );
        return;
      }

      // --------------------------- auto applied coupon code ---------------------------
      const { businessInfo } = this.state;
      // await this._autoAppliedPromoCodeApplyDetect(
      //   businessInfo && businessInfo.merchantId
      // );

      this.moveStepTo(this.STEPS.CHECKINGOUT);
      setTimeout(() => {
        try {
          window.scrollTo(0, 0);
        } catch (ex) {}
      }, 0);

      if (isSmallDevice()) {
        this.setState({
          showShoppingCart: false,
        });
      }

      // ---------- initial free addon items if available -----------
      const orderPaymentObject = await this.getOrderPaymentObject();

      const inOrderPromotionAvailableTime = !!(
        businessInfo &&
        businessInfo.orderPromotionsAvailableTime &&
        checkIfWithinOrderPromotionHour(
          businessInfo.orderPromotionsAvailableTime
        )
      );
      if (
        businessInfo &&
        businessInfo.enableOrderPromotion &&
        inOrderPromotionAvailableTime &&
        businessInfo.orderPromotions
      ) {
        const orderPromotions = businessInfo.orderPromotions;
        orderPromotions.sort((obj1, obj2) => {
          if (obj1.amount < obj2.amount) return 1;
          else if (obj1.amount > obj2.amount) return -1;
          return 0;
        });

        let freeItemNames_selected = null;
        for (let index = 0; index < orderPromotions.length; index++) {
          const { amount, freeItemNames } = orderPromotions[index];
          if (
            orderPaymentObject &&
            orderPaymentObject.subtotal != null &&
            orderPaymentObject.subtotal >= amount
          ) {
            freeItemNames_selected = freeItemNames;

            return this.setState({
              freeItemNames_selected,
              amount_selected: amount,
              showOrderPromotionModal: true,
              freeItemNameSelected: null,
            });
          }
        }
      }
      this.setState({
        freeItemNameSelected: null,
      });
    } finally {
      setTimeout(async () => {
        await this._autoAppliedPromoCodeApplyDetect(
          this.state.businessInfo && this.state.businessInfo.merchantId
        );
      }, 0);
      this.endLoading();
    }
  };

  handleBackToMenuClick = () => {
    this.moveStepTo(this.STEPS.ORDERING);
    this.setState({
      // scheduleTimeSelected: false, // false asap, true schedule
      // scheduleTimeStr: null,
      // scheduleDate: null,
    });

    setTimeout(() => {
      try {
        window.scrollTo(0, 0);
      } catch (ex) {}
    }, 0);
  };

  handleOrderTipChange = (tip_pct) => {
    this.setState({
      order_tips_pct: Number(tip_pct),
    });
  };

  handleOrderCustomTipClick = () => {
    this.setState({
      order_tips_pct: "other",
    });
  };

  handleCustomTipChange = (val) => {
    if (Number.isNaN(Number(val)) || Number(val) > 99999) {
      return;
    }

    this.setState({
      custom_tips: val,
    });
  };

  handleHideNeedCardInfoMsg = () => {
    // this.setState({
    //   needCardInfoMsgShow: false,
    // });
    this.hideOrderSummaryErrorMsg();
  };

  // handleScheduleTimeOptionSelect = (selected) => {
  //   this.setState({ scheduleTimeSelected: !!selected });
  //   if (!selected) {
  //     const withinBusinessHour = checkIfWithinBusinessHour(
  //       this.state.businessInfo && this.state.businessInfo.businessHour,
  //       null
  //     );
  //     this.setState({
  //       scheduleDate: null,
  //       scheduleTimeStr: "",
  //       withinBusinessHour,
  //     });
  //   }
  // };

  handleScheduleDateSelect = (dateSelected) => {
    this.setState({ scheduleDate: dateSelected });
  };

  handleScheduleDateClick = () => {
    const xDayBusinessHours = getXDayBusinessHour(
      this.state.businessInfo && this.state.businessInfo.businessHour,
      1
    );

    for (let day in xDayBusinessHours || []) {
      if (
        !xDayBusinessHours[day].disable &&
        checkIfWithinBusinessHour(
          this.state.businessInfo && this.state.businessInfo.businessHour,
          xDayBusinessHours[day].date
        )
      ) {
        this.handleOrderDateSelect(xDayBusinessHours[day]);
        break;
      }
    }

    this.setState({
      scheduledTimeSelectOpen: true,
      xDayBusinessHours,
      withinBusinessHour: true,
      scheduleTimeSelected: true,
    });
  };

  handleAsapOptionSelect = () => {
    this.setState({
      scheduledTimeSelectOpen: false,
      scheduleDate: null,
      scheduleTimeSelected: false,
    });
  };

  handleScheduleDateSelectModalClose = () => {
    this.setState({
      scheduledTimeSelectOpen: false,
      scheduleDate: null,
      scheduleTimeSelected: false,
    });
  };

  // handleScheduleDateClick = (selected) => {
  //   if (!!selected) {
  //     const xDayBusinessHours = getXDayBusinessHour(
  //       this.state.businessInfo && this.state.businessInfo.businessHour,
  //       3
  //     );
  //     for (let i in xDayBusinessHours) {
  //       if (
  //         !xDayBusinessHours[i].disable &&
  //         checkIfWithinBusinessHour(
  //           this.state.businessInfo && this.state.businessInfo.businessHour,
  //           xDayBusinessHours[i].date
  //         )
  //       ) {
  //         this.handleOrderDateSelect(xDayBusinessHours[i]);
  //         break;
  //       }
  //     }
  //     this.setState({
  //       scheduledTimeSelectOpen: !this.state.scheduledTimeSelectOpen,
  //       xDayBusinessHours,
  //       withinBusinessHour: true,
  //     });
  //   }
  //   this.handleScheduleTimeOptionSelect(!!selected);
  // };

  handleScheduledTimeChange = async (val) => {
    if (val) {
      const hours = Math.floor(val / 60);
      const mins = val - hours * 60;
      let updateDate = new Date(this.state.scheduleDate);
      updateDate.setHours(hours, mins);
      const merchantId = this.state.merchantId;
      const categories = await MenuDb.getCategories(merchantId);
      // ---- sort categories, put current unavailable categories in the end of display list
      (categories || []).sort((cate1, cate2) => {
        if (!cate1 && !cate2) return 0;
        if (!cate1) return 1;
        if (!cate2) return -1;

        const availableHour1 = cate1.availableHour;
        const availableHour2 = cate2.availableHour;

        const ifAvailable1 = checkIfWithinBusinessHour2(
          availableHour1,
          updateDate
        );
        const ifAvailable2 = checkIfWithinBusinessHour2(
          availableHour2,
          updateDate
        );
        if ((ifAvailable1 && ifAvailable2) || (!ifAvailable1 && !ifAvailable2))
          return 0;
        if (!ifAvailable1) return 1;
        else return -1;
      });
      this.setState({
        scheduleTimeStr: getTimeStringFromMin(val),
        scheduledTimeSelectOpen: false,
        scheduleDate: updateDate,
        categories,
      });
      this.loadingItems(merchantId, categories);
    }
  };

  handlePlaceOrderClick = async (amount, recaptchaToken) => {
    const businessInfo =
      this.props.merchantId &&
      (await BusinessInfoDb.getBusinessInfo(this.props.merchantId));

    const withinBusinessHour = checkIfWithinBusinessHour(
      businessInfo && businessInfo.businessHour,
      this.state.scheduleDate
    );

    if (!withinBusinessHour) {
      this.showOrderSummaryErrorMsg("Store closed now!", null, 5000);
      return;
    }

    const hasUnavailableItem = (this.state.orderedItems || []).some(
      ({ availableHour }) => {
        return (
          availableHour &&
          !checkIfWithinBusinessHour2(availableHour, this.state.scheduleDate)
        );
      }
    );

    if (hasUnavailableItem) {
      this.showOrderSummaryErrorMsg(
        "Please remove unavailable item(s) to continue.",
        null,
        5000
      );
      return;
    }

    // verify if car info has been entered if method is curbside
    if (
      this.state.orderMethod === this.METHOD.CURBSIDE &&
      (!this.state.carMakeModel || !this.state.carColor)
    ) {
      this.showOrderSummaryErrorMsg(
        "Please enter car information to continue.",
        null,
        5000
      );
      return;
    }

    if (this.state.scheduleTimeSelected && !this.state.scheduleTimeStr) {
      this.showOrderSummaryErrorMsg(
        "Please Select a Scheduled Time for Scheduled Order",
        null,
        5000
      );
      return;
    }

    // verify if customer address has been entered if method is delivery
    if (
      this.state.orderMethod === this.METHOD.DELIVERY &&
      !this.state.customerAddressStr
    ) {
      this.showOrderSummaryErrorMsg(
        "Please provide your delivery address.",
        null,
        5000
      );

      return;
    }

    // verify if customer info has been entered   customerPhone: null, customerName: null,
    const roomService = (this.state.businessInfo || {}).roomService;
    if (
      !roomService &&
      (!this.state.customerPhone ||
        !this.state.customerName ||
        verifyCustomerPhone(this.state.customerPhone).error)
    ) {
      this.showOrderSummaryErrorMsg(
        "Please enter your valid phone number and your name to continue.",
        null,
        5000
      );
      return;
    }

    if (
      !!roomService &&
      (!this.state.roomServiceRoom || !this.state.customerName)
    ) {
      this.showOrderSummaryErrorMsg(
        "Please enter your Room Number and your Last Name to continue.",
        null,
        5000
      );
      return;
    }

    // verify if schedule time is selected if scheduled order has been selected
    if (this.state.scheduleTimeSelected && !this.state.scheduleTimeStr) {
      this.showOrderSummaryErrorMsg(
        "Select a time for scheduled order, or select ASAP",
        null,
        5000
      );
      return;
    }

    const orderPaymentObject = await this.getOrderPaymentObject();

    if (
      this.state.orderMethod === this.METHOD.DELIVERY &&
      orderPaymentObject &&
      orderPaymentObject.subtotal < this.state.minOrderForDelivery
    ) {
      this.showOrderSummaryErrorMsg(
        "Sorry, Your order amount does not meet the minimum requirement for delivery.",
        null,
        5000
      );
      return;
    }

    // --------------------------- checking if there exist order promotion ---------------------------

    const { freeItemNameSelected } = this.state;
    let index = null;
    if (freeItemNameSelected) {
      const orderedItems = [...(this.state.orderedItems || [])];
      orderedItems.push({
        name: freeItemNameSelected,
        price: 0,
        note: "Free addon item",
      });

      if (orderedItems && orderedItems.length > 0) {
        await ShoppingCartDb.updateOrderItems(
          this.state.merchantId,
          orderedItems
        );
        index = orderedItems.length - 1;
      }

      this.setState({
        orderedItems: orderedItems,
      });
    }

    // ------------------------------- creditcard -----------------------------------
    if (this.state.paymentMethod === this.PAYMENT_METHOD.CREDITCARD) {
      const success = await this.handlePayOrderByCreditcard(
        orderPaymentObject,
        recaptchaToken
      );

      console.log(99, success);

      if (!success && index != null) {
        const orderedItems = [...(this.state.orderedItems || [])];
        orderedItems.splice(index, 1);

        if (orderedItems && orderedItems.length > 0) {
          await ShoppingCartDb.updateOrderItems(
            this.state.merchantId,
            orderedItems
          );
        }

        this.setState({
          orderedItems: orderedItems,
        });
      }
    } else {
      await this.handlePayOrderByCash(orderPaymentObject);
    }
  };

  // return null if not orderedItem found in shoppingCart table
  getOrderPaymentObject = async () => {
    const {
      merchantId,
      order_tips_pct,
      fixedTipRate,
      custom_tips,
      tax_rate,
      orderMethod,
      promoCodeApplied,
    } = this.state;

    // --------------------- shopping cart info -----------------------
    const orderedItems = await ShoppingCartDb.getOrderItems(merchantId);
    // const orderedItems = this.state.orderedItems;

    if (!orderedItems || orderedItems.length <= 0) {
      return null;
    }

    const subtotal = calSubTotal(orderedItems || []);
    const tax = subtotal * tax_rate;
    const tips =
      order_tips_pct === "other"
        ? custom_tips
          ? Number(custom_tips)
          : 0
        : subtotal * order_tips_pct;

    const fixedTips = subtotal * (fixedTipRate || 0);

    const discount =
      promoCodeApplied && subtotal * promoCodeApplied.percentageOff;

    const { processingFeeRate, serviceFeeAmt } = this.state.businessInfo || {};

    const deliveryFee = this.state.deliveryFee;
    const roomServiceDeliveryFee = this.state.roomServiceDeliveryFee;

    const addedDeliveryFee =
      orderMethod === this.METHOD.DELIVERY && deliveryFee ? deliveryFee : 0;

    const addedRoomServiceDeliveryFee =
      orderMethod === this.METHOD.ROOM_SERVICE_DELIVERY &&
      roomServiceDeliveryFee
        ? roomServiceDeliveryFee
        : 0;
    // processingFee calculated if applied

    const processingFeeAmt =
      orderMethod == this.METHOD.DINEIN
        ? 0
        : processingFeeRate && subtotal * processingFeeRate;

    const total =
      subtotal +
      tax +
      tips -
      (discount || 0) +
      (processingFeeAmt || 0) +
      (serviceFeeAmt || 0) +
      (addedDeliveryFee || 0) +
      fixedTips +
      (addedRoomServiceDeliveryFee || 0);

    return {
      subtotal,
      tax,
      tips,
      fixedTips,
      discount,
      addedDeliveryFee,
      addedRoomServiceDeliveryFee,
      processingFeeAmt,
      serviceFeeAmt: serviceFeeAmt || 0,
      total,
    };
  };

  handlePayOrderByCreditcard = async (
    orderPaymentObject_optional,
    recaptchaToken
  ) => {
    const allowAmerExpr =
      this.state.businessInfo &&
      this.state.businessInfo.allowAmericanExpressCard;

    // pre-verify if payment info
    if (
      verifyCardNumber(this.state.cardNumber, !allowAmerExpr).error ||
      verifyCreditCardCVC(this.state.cardCVV).error ||
      verifyCreditCardExpiration(this.state.cardExpiration).error ||
      !this.state.billingAddressStr ||
      verifyUSPostalCode(this.state.billingZipCode).error
    ) {
      this.showOrderSummaryErrorMsg(
        "Your order has NOT been placed yet!",
        "Invalid credit card information provided.",
        0
      );
      return false;
    }

    this.setState({
      needCustomerAddrMsgShow: false,
      needCustomerInfoMsgShow: false,
      needCardInfoMsgShow: false,
    });

    const {
      orderMethod,
      dineinTableName,
      customerAddressStr,
      deliveryNote,
      customerName,
      customerPhone,
      customerEmail,
      carMakeModel,
      carColor,
      receiveOrderInfo,
      scheduleTimeStr,
      scheduleDate,
      roomServiceRoom,
      isBranchSelected,
    } = this.state;

    this.startLoading(
      "Your payment being processed. Do not close this window."
    );

    const orderPaymentObj =
      orderPaymentObject_optional || (await this.getOrderPaymentObject());

    if (!orderPaymentObj) {
      // no record found in database
      this.showOrderSummaryErrorMsg(
        "Oops, it looks like we can not place your order. Please enable your cookie in your browser, or call restaurant directly to place the order",
        null,
        10000
      );
      return this.endLoading();
    }

    // ---------------------- verify phone number entered -----------------------------------
    const phoneVerified = await VerificationDb.verifyPhoneNum(
      customerPhone,
      recaptchaToken
    );

    if (!phoneVerified) {
      this.endLoading();
      return this.setState({
        phoneVerificationModalShow: true,
        recaptchaToken: recaptchaToken,
        orderPaymentObj,
      });
    }

    const {
      subtotal,
      tax,
      tips,
      fixedTips,
      discount,
      addedDeliveryFee,
      addedRoomServiceDeliveryFee,
      processingFeeAmt,
      serviceFeeAmt,
      total,
    } = orderPaymentObj;

    const cardInfo = {
      acount: this.state.cardNumber,
      expiry: this.state.cardExpiration,
      cvv2: this.state.cardCVV,
      postal: this.state.billingZipCode,
      billingAddr: this.state.billingAddressStr,
      name: this.state.customerName,
      phone: this.state.customerPhone,
      email: this.state.customerEmail,
    };

    const authResponse = await ECommDb.authorizePayment(
      this.state.merchantId,
      total,
      cardInfo,
      recaptchaToken
    );

    // const authResponse = await ECommDb.authorizePayment(
    //   this.state.merchantId,
    //   total,
    //   cardInfo,
    //   (tips || 0) + (fixedTips || 0)
    // );

    console.log(123, authResponse);

    let orderMethodStr = "other";
    if (orderMethod === this.METHOD.PICKUP) {
      orderMethodStr = "pickup";
    } else if (orderMethod === this.METHOD.DELIVERY) {
      orderMethodStr = "delivery";
    } else if (orderMethod === this.METHOD.CURBSIDE) {
      orderMethodStr = "curbside";
    } else if (orderMethod === this.METHOD.DINEIN) {
      orderMethodStr = "dinein";
    } else if (orderMethod === this.METHOD.ROOM_SERVICE_DELIVERY) {
      orderMethodStr = "roomServiceDelivery";
    }

    if (authResponse && authResponse.approved) {
      const updatedShoppingCart = await ShoppingCartDb.updateOrderCheckoutInfo(
        this.state.merchantId,
        {
          subtotal,
          tax,
          tips,
          fixedTips,
          discount: discount,
          processingFeeAmt: processingFeeAmt,
          serviceFeeAmt: serviceFeeAmt,
          deliveryFee: addedDeliveryFee,
          roomServiceDeliveryFee: addedRoomServiceDeliveryFee,
          total,
          orderMethod: orderMethodStr,
          dineinTableName: dineinTableName,
          customerAddrStr: customerAddressStr,
          deliveryNote: deliveryNote,
          customerName,
          customerEmail,
          phone: customerPhone,
          paymentMethod: "creditcard",
          carMakeModel,
          carColor,
          receiveOrderInfo,
          scheduleTimeStr,
          scheduleDate,
          roomServiceRoom,
          isBranch: isBranchSelected,
        }
      );

      // const updatedShoppingCart = null;

      // if (!updatedShoppingCart || !updatedShoppingCart.total) {
      //   this.showOrderSummaryErrorMsg(
      //     "Oops, it looks like we can not place your order. Please enable your cookie in your browser, or call restaurant directly to place the order",
      //     null,
      //     10000
      //   );
      //   return this.endLoading();
      // }

      const savedOrder = await ShoppingCartDb.sendOrder(
        this.state.merchantId,
        this.props.userInfo,
        authResponse
      );

      // if (this.props.userInfo.keys().length > 0) {
      //   let order_zipcode = "";
      //   if ("order_zipcode" in this.props.userInfo) {
      //     order_zipcode = this.props.userInfo.order_zipcode;
      //   }
      //   let updatedUser = {
      //     name: this.props.userInfo.name,
      //     order_address: this.state.billingAddressStr,
      //     order_phone: this.state.customerPhone,
      //     order_name: this.state.customerName,
      //     order_zipcode: order_zipcode,
      //   };
      //   let updated = await AuthDb.updateUserInfo(updatedUser);
      // }

      if (savedOrder) {
        const removed = await ShoppingCartDb.emptyShoppingChart(
          this.state.merchantId
        );
        // const url = `/store/${this.state.merchantId}/order_placed/${savedOrder.orderId}`;
        const url = `/store/${this.state.merchantId}/order_placed/${savedOrder._id}`;
        toUrl(url);
        return true;
      } else {
        // return false;
      }
    } else {
      // this.setState({
      //   needCardInfoMsgShow: true,
      // });

      this.showOrderSummaryErrorMsg(
        "Your order has NOT been placed yet!",
        "Invalid credit card information provided.",
        0
      );

      // setTimeout(() => {
      //   this.setState({
      //     needCardInfoMsgShow: false,
      //   });
      // }, 2500);
    }
    this.endLoading();
    return false;
  };

  handlePayOrderByCash = async (orderPaymentObject_optional) => {
    // ---------------------------   cash -----------------------------------
    this.setState({
      needCustomerAddrMsgShow: false,
      needCustomerInfoMsgShow: false,
    });

    const {
      orderMethod,
      dineinTableName,
      customerAddressStr,
      deliveryNote,
      customerName,
      customerEmail,
      customerPhone,
      promoCodeApplied,
      carMakeModel,
      carColor,
      receiveOrderInfo,
      scheduleTimeStr,
      scheduleDate,
      roomServiceRoom,
      isBranchSelected,
    } = this.state;

    this.startLoading(
      "Your payment being processed. Do not close this window."
    );

    const orderPaymentObj =
      orderPaymentObject_optional || (await this.getOrderPaymentObject());

    if (!orderPaymentObj) {
      // no record found in database
      this.showOrderSummaryErrorMsg(
        "Oops, it looks like we can not place your order. Please enable your cookie in your browser, or call restaurant directly to place the order",
        null,
        10000
      );
      return this.endLoading();
    }

    // ---------------------- verify phone number entered -----------------------------------
    const phoneVerified = await VerificationDb.verifyPhoneNum(customerPhone);

    if (!phoneVerified) {
      this.endLoading();
      return this.setState({
        phoneVerificationModalShow: true,
        orderPaymentObj,
      });
    }

    const {
      subtotal,
      tax,
      tips,
      fixedTips,
      discount,
      addedDeliveryFee,
      addedRoomServiceDeliveryFee,
      processingFeeAmt,
      serviceFeeAmt,
      total,
    } = orderPaymentObj;

    let orderMethodStr = "other";
    if (orderMethod === this.METHOD.PICKUP) {
      orderMethodStr = "pickup";
    } else if (orderMethod === this.METHOD.DELIVERY) {
      orderMethodStr = "delivery";
    } else if (orderMethod === this.METHOD.CURBSIDE) {
      orderMethodStr = "curbside";
    } else if (orderMethod === this.METHOD.DINEIN) {
      orderMethodStr = "dinein";
    } else if (orderMethod === this.METHOD.ROOM_SERVICE_DELIVERY) {
      orderMethodStr = "roomServiceDelivery";
    }

    const updatedShoppingCart = await ShoppingCartDb.updateOrderCheckoutInfo(
      this.state.merchantId,
      {
        subtotal,
        tax,
        tips,
        fixedTips,
        discount: discount,
        processingFeeAmt: processingFeeAmt,
        serviceFeeAmt: serviceFeeAmt,
        deliveryFee: addedDeliveryFee,
        roomServiceDeliveryFee: addedRoomServiceDeliveryFee,
        total,
        orderMethod: orderMethodStr,
        dineinTableName,
        customerAddrStr: customerAddressStr,
        deliveryNote: deliveryNote,
        customerName,
        customerEmail,
        phone: customerPhone,
        paymentMethod: "cash",
        carMakeModel,
        carColor,
        receiveOrderInfo,
        scheduleTimeStr,
        scheduleDate,
        roomServiceRoom,
        isBranch: isBranchSelected,
      }
    );

    if (!updatedShoppingCart || !updatedShoppingCart.total) {
      this.showOrderSummaryErrorMsg(
        "Oops, it looks like we can not place your order. Please enable your cookie in your browser, or call restaurant directly to place the order",
        null,
        10000
      );
      return this.endLoading();
    }

    const sentOrder = await ShoppingCartDb.sendOrder(
      this.state.merchantId,
      this.props.userInfo
    );
    if (sentOrder) {
      const removed = await ShoppingCartDb.emptyShoppingChart(
        this.state.merchantId
      );
      // const url = `/store/${this.state.merchantId}/order_placed/${sentOrder.orderId}`;
      const url = `/store/${this.state.merchantId}/order_placed/${sentOrder._id}`;
      toUrl(url);
      return true;
    } else {
      this.showOrderSummaryErrorMsg(
        "Oops, it looks like we can not place your order. Please enable your cookie in your browser, or call restaurant directly to place the order",
        null,
        10000
      );
    }

    this.endLoading();
  };

  // ----------------------- payment ---------------------------
  handlePaymentMethodChange = (paymentMethod) => {
    this.setState({
      paymentMethod: paymentMethod,
    });
  };

  handleShoppingCartIconClick = () => {
    // if (isSmallDevice() &&  ) return;

    if (this.state.showShoppingCart) {
      this.setState({ showShoppingCart: false });
    } else {
      this.setState({ showShoppingCart: true });
    }
  };

  handleShoppingCartCloseIconClick = () => {
    this.setState({ showShoppingCart: false });
  };

  handleCategoryListIconClick = () => {
    this.setState({ showCategoryListModal: true });
  };

  handleMethodChange = async (orderMethod) => {
    if (
      orderMethod === this.METHOD.PICKUP ||
      orderMethod === this.METHOD.CURBSIDE ||
      orderMethod === this.METHOD.ROOM_SERVICE_DELIVERY
    ) {
      this.setState({
        lat: this.state.store_lat,
        lng: this.state.store_lng,
        orderMethod: orderMethod,
      });
    } else if (orderMethod === this.METHOD.DELIVERY) {
      const { customerAddr_lat, customerAddr_lng, customerAddressStr } =
        this.state;
      if (customerAddr_lat != null && customerAddr_lng != null) {
        this.setState({
          lat: customerAddr_lat,
          lng: customerAddr_lng,
          orderMethod: orderMethod,
        });
      } else if (customerAddressStr != null) {
        const response = await Geocode.fromAddress(customerAddressStr);
        console.log("call once");
        const location = response && response.results[0].geometry.location;
        const { lat, lng } = location || {};
        this.setState({
          lat,
          lng,
          customerAddr_lat: lat,
          customerAddr_lng: lng,
          orderMethod: orderMethod,
        });
      } else {
        this.setState({ lat: null, lng: null, orderMethod: orderMethod });
      }
    } else if (orderMethod === this.METHOD.DINEIN) {
      this.setState({
        orderMethod: orderMethod,
      });
    }

    if (orderMethod === this.METHOD.DELIVERY) {
      this.setState({
        order_tips_pct: 0.15,
      });
    } else {
      this.setState({
        order_tips_pct: 0.1,
      });
    }
  };

  // geometry:{location:{lat(), lng()}}
  handleCustomerAddressSubmit = async (addressStr, postalCode, geometry) => {
    if (addressStr) {
      const { store_lat, store_lng, deliveryFees } = this.state;
      // const response = await Geocode.fromAddress(addressStr);
      // const location = response && response.results[0].geometry.location;
      // const { lat, lng } = location || {};
      let lat, lng;
      const { lat: getLat, lng: getLng } = (geometry || {}).location || {};
      if (getLat && getLng) {
        lat = getLat();
        lng = getLng();
      } else {
        const response = await Geocode.fromAddress(addressStr);
        const location = response && response.results[0].geometry.location;
        lat = (location || {}).lat;
        lng = (location || {}).lng;
        console.log("Google api called once");
      }

      // check if the address is within the delivery range
      const distance =
        Number(
          await getGoogleDistance(
            { latitude: store_lat, longitude: store_lng },
            { latitude: lat, longitude: lng }
          )
        ) ||
        getDistanceInMiles(
          { latitude: store_lat, longitude: store_lng },
          { latitude: lat, longitude: lng }
        );

      if (!deliveryFees || deliveryFees.length <= 0) {
        // return this.setState({
        //   lat,
        //   lng,
        //   customerAddressStr: addressStr,
        //   customerPostalCode: postalCode,
        //   deliveryFee: 0,
        // });
        return this.setState({
          deliveryOutRangeWarning: true,
        });
      }

      deliveryFees.sort((obj1, obj2) => {
        if (obj1.deliveryRangeInMile < obj2.deliveryRangeInMile) return -1;
        else if (obj1.deliveryRangeInMile > obj2.deliveryRangeInMile) return 1;
        return 0;
      });

      let deliveryFee_selected = null;
      for (let index = 0; index < deliveryFees.length; index++) {
        const { deliveryRangeInMile, deliveryFee } = deliveryFees[index];
        if (distance < deliveryRangeInMile) {
          deliveryFee_selected = deliveryFee;
          break;
        }
      }

      // console.log(234, distance, deliveryFee_selected, deliveryFees);

      if (deliveryFee_selected == null) {
        return this.setState({
          deliveryOutRangeWarning: true,
        });
      } else {
        return this.setState({
          lat,
          lng,
          customerAddressStr: addressStr,
          customerPostalCode: postalCode,
          deliveryFee: deliveryFee_selected,
          customerAddr_lat: lat,
          customerAddr_lng: lng,
        });
      }
    } else {
      this.setState({
        lat: null,
        lng: null,
        customerAddressStr: null,
        customerPostalCode: null,
        customerAddr_lat: null,
        customerAddr_lng: null,
      });
    }
  };

  handleCustomerAddrChangeClick = () => {
    // const {
    //   customerAddr_lat,
    //   customerAddr_lng,
    //   customerAddressStr,
    // } = this.state;
    // if (customerAddr_lat != null && customerAddr_lng != null) {
    //   this.setState({
    //     lat: customerAddr_lat,
    //     lng: customerAddr_lng,
    //   });
    // } else if (customerAddressStr != null) {
    //   const response = await Geocode.fromAddress(customerAddressStr);
    //   console.log("call once");
    //   const location = response && response.results[0].geometry.location;
    //   const { lat, lng } = location || {};
    //   this.setState({
    //     lat,
    //     lng,
    //     customerAddr_lat: lat,
    //     customerAddr_lng: lng,
    //   });
    // } else {
    //   this.setState({ lat: null, lng: null, orderMethod: orderMethod });
    // }

    this.setState({
      lat: null,
      lng: null,
      customerAddr_lat: null,
      customerAddr_lng: null,
      customerAddressStr: null,
      customerPostalCode: null,
      deliveryOutRangeWarning: false,
    });
  };

  handleSameAsDeliveryAddrClick = (checked) => {
    if (checked) {
      this.setState({
        billingAddressStr: this.state.customerAddressStr,
        billingZipCode: this.state.customerPostalCode,
      });
    }
  };

  handleReceiveOrderInfoClick = (checked) => {
    this.setState({
      receiveOrderInfo: checked,
    });
  };

  handleCarMakeModelChange = (val) => {
    this.setState({
      carMakeModel: val,
    });
  };

  handleCarColorChange = (val) => {
    this.setState({
      carColor: val,
    });
  };

  handleDeliveryNoteChange = (note) => {
    this.setState({
      deliveryNote: note,
    });
  };

  handleCustomerNameChange = (name) => {
    this.setState({
      customerName: name,
    });
  };

  handleCustomerPhoneChange = (phone) => {
    this.setState({
      customerPhone: phone,
    });
  };

  handleCustomerEmailChange = (email) => {
    this.setState({
      customerEmail: email,
    });
  };

  handleBillAddressChange = (addressStr) => {
    this.setState({
      billingAddressStr: addressStr,
    });
  };

  handleBillZipCodeChange = (zipCode) => {
    this.setState({
      billingZipCode: zipCode,
    });
  };

  handleMerchantWebsiteClick = (url) => {
    url && toUrl(url);
  };

  handlePromoCodeChange = (val) => {
    this.setState({
      promoCode: val,
    });
  };

  handlePromoCodeApplyClick = async () => {
    const promoCode = await BusinessInfoDb.getPromoCode(
      this.state.merchantId,
      this.state.promoCode
    );
    this.setState({
      promoCodeApplied: promoCode && promoCode.percentageOff ? promoCode : null,
    });
    return promoCode && promoCode.percentageOff ? true : false;
  };

  handlePromoCodeRemove = () => {
    this.setState({
      promoCodeApplied: null,
    });
  };

  //--------------- payment --------------------------
  handleCardNumberChange = (cardNumber) => {
    this.setState({
      cardNumber: cardNumber,
    });
  };

  handleCardExpirationChange = (cardExpiration) => {
    this.setState({
      cardExpiration: cardExpiration,
    });
  };

  handleCardCVVChange = (cardCVV) => {
    this.setState({
      cardCVV: cardCVV,
    });
  };

  //--------------------------- delivery/pickup ------------------------------
  // not in use
  handleOrderMethodModalClose = async () => {
    const addressStr = formatAddress(
      this.state.businessInfo && this.state.businessInfo.address
    );
    const response = addressStr && (await Geocode.fromAddress(addressStr));
    console.log("Google map api called once");
    const location = response && response.results[0].geometry.location;
    const { lat, lng } = location || {};

    this.setState({
      showOrderMethodSelectModal: false,
      lat,
      lng,
    });
  };

  handlePickupMethodClick = async () => {
    // const addressStr = formatAddress(
    //   this.state.businessInfo && this.state.businessInfo.address
    // );
    // const response = addressStr && (await Geocode.fromAddress(addressStr));
    // const location = response && response.results[0].geometry.location;
    // const { lat, lng } = location || {};

    const { store_lat, store_lng } = this.state;

    this.setState({
      showOrderMethodSelectModal: false,
      lat: store_lat,
      lng: store_lng,
    });
  };

  handleDeliveryMethodClick = () => {
    if (this.state.orderMethod == this.METHOD.DELIVERY) {
      return;
    }

    this.setState({
      showDeliveryAddrModal: true,
      lat: null,
      lng: null,
    });
  };

  handleOrderDateSelect = (hourInfo) => {
    if (!hourInfo) return null;

    const config = {
      year: "numeric",
      month: "short",
      day: "2-digit",
    };

    const dateTimeFormat = new Intl.DateTimeFormat("default", config);
    let todaystr = dateTimeFormat.format(new Date());
    if (todaystr == hourInfo.datestr) {
      let timeSchedualList = getTimeScheduleList(
        null,
        hourInfo && hourInfo.close
      );
      this.setState({ timeSchedualList });
    } else {
      let timeSchedualList = getTimeScheduleList(
        hourInfo.open,
        hourInfo && hourInfo.close
      );
      this.setState({ timeSchedualList });
    }
    this.handleScheduleDateSelect(hourInfo.date);
  };

  handleDeliveryAddrModalClose = () => {
    this.setState({
      showDeliveryAddrModal: false,
      lat: null,
      lng: null,
      customerAddressStr: null,
      deliveryOutRangeWarning: false,
      orderMethod: this.METHOD.PICKUP,
      order_tips_pct: 0.1,
    });
  };

  handleDeliveryAddrBackClick = () => {
    const { store_lng, store_lat } = this.state;

    this.setState({
      showDeliveryAddrModal: false,

      customerAddressStr: null,
      deliveryOutRangeWarning: false,
      orderMethod: this.METHOD.PICKUP,
      order_tips_pct: 0.1,
      lat: store_lat,
      lng: store_lng,
    });
  };

  handleDeliveryAddrDoneClick = () => {
    if (!this.state.customerAddressStr) {
      this.setState({
        needCustomerAddrMsgShow: true,
      });

      return setTimeout(() => {
        this.setState({
          needCustomerAddrMsgShow: false,
        });
      }, 3000);

      // this.showOrderSummaryErrorMsg(
      //   "Please provide your delivery address.",
      //   null,
      //   5000
      // );

      return;
    }

    this.setState({
      showDeliveryAddrModal: false,
      showOrderMethodSelectModal: false,
    });
  };

  // geometry:{location:{lat(), lng()}}
  handleDeliveryAddrSelected = async (addressStr, postalCode, geometry) => {
    const { store_lat, store_lng, deliveryFees } = this.state;

    this.setState({
      loading_googleMap: true,
    });

    if (addressStr) {
      // const response = await Geocode.fromAddress(addressStr);
      // console.log("Google api called once");
      // const location = response && response.results[0].geometry.location;
      // const { lat, lng } = location || {};

      let lat, lng;
      const { lat: getLat, lng: getLng } = (geometry || {}).location || {};
      if (getLat && getLng) {
        lat = getLat();
        lng = getLng();
      } else {
        const response = await Geocode.fromAddress(addressStr);
        console.log("Google api called once");
        const location = response && response.results[0].geometry.location;
        lat = (location || {}).lat;
        lng = (location || {}).lng;
      }

      // check if the address is within the delivery range
      const distance =
        Number(
          await getGoogleDistance(
            { latitude: store_lat, longitude: store_lng },
            { latitude: lat, longitude: lng }
          )
        ) ||
        getDistanceInMiles(
          { latitude: store_lat, longitude: store_lng },
          { latitude: lat, longitude: lng }
        );

      if (!deliveryFees || deliveryFees.length <= 0) {
        // return this.setState({
        //   lat,
        //   lng,
        //   customerAddressStr: addressStr,
        //   customerPostalCode: postalCode,
        //   deliveryFee: 0,
        //   orderMethod: this.METHOD.DELIVERY,
        //   loading_googleMap: false,
        // });
        return this.setState({
          deliveryOutRangeWarning: true,
          loading_googleMap: false,
        });
      }

      deliveryFees.sort((obj1, obj2) => {
        if (obj1.deliveryRangeInMile < obj2.deliveryRangeInMile) return -1;
        else if (obj1.deliveryRangeInMile > obj2.deliveryRangeInMile) return 1;
        return 0;
      });

      let deliveryFee_selected = null;
      for (let index = 0; index < deliveryFees.length; index++) {
        const { deliveryRangeInMile, deliveryFee } = deliveryFees[index];
        if (distance < deliveryRangeInMile) {
          deliveryFee_selected = deliveryFee;
          break;
        }
      }

      // console.log(123, distance, deliveryFee_selected, deliveryFees);

      if (deliveryFee_selected == null) {
        return this.setState({
          deliveryOutRangeWarning: true,
          loading_googleMap: false,
        });
      } else {
        return this.setState({
          lat,
          lng,
          customerAddressStr: addressStr,
          customerPostalCode: postalCode,
          deliveryFee: deliveryFee_selected,
          orderMethod: this.METHOD.DELIVERY,
          order_tips_pct: 0.15,
          loading_googleMap: false,
        });
      }
    } else {
      this.setState({
        lat: null,
        lng: null,
        customerAddressStr: addressStr,
        customerPostalCode: postalCode,
        orderMethod: this.METHOD.PICKUP,
        order_tips_pct: 0.1,
        loading_googleMap: false,
      });
    }
  };

  // --------------------- business info ---------------------
  handleBusinessNoticeCloseClick = () => {
    this.setState({
      showBusinessNotice: false,
    });
  };

  handleBusinessNoticeDisplayClick = () => {
    this.setState({
      showBusinessNotice: true,
    });
  };

  //----------------------------------------------------------

  expandOrderItemsIfPromoMethod = (orderedItems, allCategories) => {
    try {
      if (!orderedItems || orderedItems.length <= 0 || !allCategories)
        return orderedItems;

      const newOrderedItems = [];
      (orderedItems || []).forEach((orderedItem) => {
        const category = allCategories.find(
          (cate) => cate._id == orderedItem._idCategory
        );

        if (category && category.promoMethod) {
          let qty = Number(orderedItem.qty);

          while (qty) {
            const _orderedItem = { ...orderedItem };
            _orderedItem.qty = 1;
            newOrderedItems.push(_orderedItem);
            qty--;
          }
        } else {
          newOrderedItems.push(orderedItem);
        }
      });

      return newOrderedItems;
    } catch (ex) {
      throw ex;
    }
  };

  // expand orderedItems if promoMethod
  getOrderedItemsAfterPromoMethod = async (items) => {
    const orderedItemsObj = groupOrderedItemsByCategory(items);
    let orderedItemsAfterPromoMethod = [];

    for (let _idCategory in orderedItemsObj) {
      const orderedItems = orderedItemsObj[_idCategory];
      const category = await MenuDb.getCategoryById(
        this.props.merchantId,
        _idCategory
      );
      if (category && category.promoMethod) {
        const orderedItemsAfterPromoMethod_each = addPromoMethod(
          orderedItems,
          category.promoMethod
        );

        orderedItemsAfterPromoMethod_each &&
          (orderedItemsAfterPromoMethod = orderedItemsAfterPromoMethod.concat(
            orderedItemsAfterPromoMethod_each
          ));
      } else {
        orderedItemsAfterPromoMethod =
          orderedItemsAfterPromoMethod.concat(orderedItems);
      }
    }

    return orderedItemsAfterPromoMethod;
  };

  // ------------------------------ order promotion ------------------------------------
  handleOrderPromotionBackClick = () => {
    this.setState({
      showOrderPromotionModal: false,
      freeItemNames_selected: null,
      amount_selected: null,
    });
  };

  handleOrderPromotionPlaceOrderClick = async () => {
    const { freeItemNameSelected } = this.state;

    this.startLoading();
    let index = null;
    if (freeItemNameSelected) {
      const orderedItems = [...(this.state.orderedItems || [])];
      orderedItems.push({
        name: freeItemNameSelected,
        price: 0,
        note: "Free addon item",
      });

      if (orderedItems && orderedItems.length > 0) {
        await ShoppingCartDb.updateOrderItems(
          this.state.merchantId,
          orderedItems
        );
        index = orderedItems.length - 1;
      }

      this.setState({
        orderedItems: orderedItems,
      });
    }
    this.endLoading();

    this.setState({
      showOrderPromotionModal: false,
    });

    if (this.state.paymentMethod === this.PAYMENT_METHOD.CREDITCARD) {
      const success = await this.handlePayOrderByCreditcard();
      if (!success && index != null) {
        const orderedItems = [...(this.state.orderedItems || [])];
        orderedItems.splice(index, 1);

        if (orderedItems && orderedItems.length > 0) {
          await ShoppingCartDb.updateOrderItems(
            this.state.merchantId,
            orderedItems
          );
        }

        this.setState({
          orderedItems: orderedItems,
        });
      }
    } else {
      await this.handlePayOrderByCash();
    }
  };

  handleOrderPromotionItemSelect = (itemName) => {
    this.setState({
      freeItemNameSelected: itemName,
    });
  };

  // ------------------------------- room service ----------------------------------------
  handleRoomServiceRoomNumberChange = (value) => {
    this.setState({
      roomServiceRoom: value,
    });
  };

  // --------------------------------- branch select -------------------------------------
  handleBranchSelect = async (isBranch) => {
    if (!this.state.merchantId) {
      return;
    }

    if (!!isBranch) {
      this.startLoading();

      const categories = (
        await MenuDb.getCategories(this.state.merchantId)
      ).filter(({ unavailableForBranch }) => !unavailableForBranch);
      // ---- sort categories, put current unavailable categories in the end of display list

      (categories || []).sort((cate1, cate2) => {
        if (!cate1 && !cate2) return 0;
        if (!cate1) return 1;
        if (!cate2) return -1;

        const availableHour1 = cate1.availableHour;
        const availableHour2 = cate2.availableHour;

        const ifAvailable1 = checkIfWithinBusinessHour2(availableHour1);
        const ifAvailable2 = checkIfWithinBusinessHour2(availableHour2);

        if ((ifAvailable1 && ifAvailable2) || (!ifAvailable1 && !ifAvailable2))
          return 0;
        if (!ifAvailable1) return 1;
        else return -1;
      });

      const partItems = await MenuDb.getItems(this.state.merchantId, 10);
      // console.log(123, partItems);

      // const allItems = await MenuDb.getItems(merchantId);

      // partItemsGroupbyCategory = [ {category:{_id, name, name_2nd}, items:[item, item,..]}, ... ]
      const partItemsGroupbyCategory = (categories || []).map((category) => {
        const items = (partItems || []).filter(
          (item) => item._idCategory === category._id
        );

        return { category, items };
      });

      this.setState({
        itemsGroupbyCategory: partItemsGroupbyCategory,
        items: partItems,
        categories: categories,
        category_selected: (categories || [])[0],

        isBranchSelected: true,
        businessInfoAddressSelected: this.state.branchAddress,
        businessNameSelected: this.state.branchName,
        showBusinessInfoAddressSelectModal: false,
      });

      setTimeout(() => {
        this.loadingItems(this.state.merchantId, categories);
      }, 0);

      this.endLoading();
    } else {
      this.setState({
        isBranchSelected: false,
        businessInfoAddressSelected: this.state.businessAddress,
        businessNameSelected: this.state.businessName,
        showBusinessInfoAddressSelectModal: false,
      });
    }
  };

  // ----------------------------- user auth & dashboard ------------------
  handleUserAuthIconClick = (e) => {
    e.preventDefault();
    if (this.state.showUserAuth) {
      this.setState({ showUserAuth: false });
    } else {
      this.setState({ showUserAuth: true });
    }
  };

  handleDashboardClose = () => {
    this.setState({ showUserDashboard: false });
  };
  handleUserInfoUpdate = (updatedUser) => {
    this.props.handleUserInfoUpdate &&
      this.props.handleUserInfoUpdate(updatedUser);
  };
  handleUserDashboard = () => {
    this.setState({ showUserDashboard: !this.state.showUserDashboard });
  };
  formatUsername = (name) => {
    if (name && name.length > 13) {
      return name.substring(0, 11) + "..";
    } else {
      return name;
    }
  };
  handleSigninClick = (user, external) => {
    this.props.onSigninClick && this.props.onSigninClick(user, external);
  };

  handleLogoutClick = () => {
    this.setState({ showUserDashboard: false });
    this.props.onLogoutClick && this.props.onLogoutClick();
  };

  // ---------------------- phone number verification ------------------
  handleSendVerificationCodeClick = async () => {
    const customerPhone = this.state.customerPhone;
    const recaptchaToken = this.state.recaptchaToken;
    if (!customerPhone || verifyCustomerPhone(customerPhone).error) {
      return this.setState({
        phoneVerificationErrorMsgShow: true,
        phoneVerificationErrorMsg: "Please enter a valid Phone number",
      });
    }

    this.setState({
      phoneVerificationCodeLoading: true,
    });
    await VerificationDb.sendVerificationCodeTo(customerPhone, recaptchaToken);
    this.setState({
      phoneVerificationCodeLoading: false,
      phoneVerificationCodeInputShow: true,
    });
  };

  handleVerificationCodeChange = (verificationCode) => {
    this.setState({
      verificationCodeEntered: verificationCode,
    });
  };

  handleVerificationCodeEnterComplete = async () => {
    const verificationCodeEntered = this.state.verificationCodeEntered;
    const phoneNum = this.state.customerPhone;
    if (!verificationCodeEntered || verificationCodeEntered.length != 4) {
      return this.setState({
        phoneVerificationErrorMsgShow: true,
        phoneVerificationErrorMsg: "Invalid code",
      });
    }

    const verified = await VerificationDb.verifyPhoneNum(
      phoneNum,
      null,
      verificationCodeEntered
    );

    if (verified) {
      const {
        phoneVerificationModalShow,
        recaptchaToken,
        orderPaymentObj,
        paymentMethod,
      } = this.state;

      if (paymentMethod === this.PAYMENT_METHOD.CREDITCARD) {
        await this.handlePayOrderByCreditcard(orderPaymentObj, recaptchaToken);
      } else {
        await this.handlePayOrderByCash(orderPaymentObj);
      }

      this.setState({
        phoneVerificationModalShow: false,
        phoneVerificationErrorMsgShow: false,
        phoneVerificationErrorMsg: "",
        phoneVerificationCodeInputShow: false,
        phoneVerificationCodeLoading: false,
        verificationCodeEntered: "",
      });
    } else {
      this.setState({
        phoneVerificationErrorMsg: "Verification Failed",
        phoneVerificationErrorMsgShow: true,
      });
    }
  };

  handleVerificationModalClose = () => {
    this.setState({
      phoneVerificationModalShow: false,
      phoneVerificationErrorMsgShow: false,
      phoneVerificationErrorMsg: "",
      phoneVerificationCodeInputShow: false,
      phoneVerificationCodeLoading: false,
      verificationCodeEntered: "",
    });
  };

  render() {
    const businessInfo = this.state.businessInfo;
    const orderPromotionAvailableTime =
      businessInfo && businessInfo.orderPromotionsAvailableTime;
    // const address = businessInfo && businessInfo.address;
    // const delivery = this.state.businessInfo;

    const enableFoodTimeSchedule =
      businessInfo && !!businessInfo.enableFoodTimeSchedule;

    const {
      orderedItems,
      // orderedItems_PromoMethod,
      order_tips_pct,
      custom_tips,
      fixedTipRate,
      tax_rate,
      customerPhone,
      customerEmail,
      category_selected,
      promoCodeApplied,
      orderMethod,
      dineinTableName, // only useful when orderMethod is Dinein

      foodPreparingTimeObj,
      freeItemNameSelected,

      roomServiceRoom,
    } = this.state;

    const subtotal = calSubTotal(orderedItems || []);
    const tax = subtotal * tax_rate;

    const tip =
      order_tips_pct === "other"
        ? custom_tips
          ? Number(custom_tips)
          : 0
        : subtotal * order_tips_pct;

    const fixedTips = subtotal * (fixedTipRate || 0);

    const discount =
      promoCodeApplied && subtotal * promoCodeApplied.percentageOff;

    const {
      processingFeeRate,
      serviceFeeAmt,
      minOrderForDelivery,
      name: merchantName,
      merchantId,
      description,
      dinein,
    } = businessInfo || {};

    const deliveryFee = this.state.deliveryFee;
    const addedDeliveryFee =
      orderMethod == this.METHOD.DELIVERY && deliveryFee ? deliveryFee : 0;

    const roomServiceDeliveryFee = this.state.roomServiceDeliveryFee;
    const addedRoomServiceDeliveryFee =
      orderMethod == this.METHOD.ROOM_SERVICE_DELIVERY && roomServiceDeliveryFee
        ? roomServiceDeliveryFee
        : 0;

    // processingFee calculated if applied
    const processingFeeAmt =
      orderMethod == this.METHOD.DINEIN
        ? 0
        : processingFeeRate && subtotal * processingFeeRate;

    const total =
      subtotal +
      tax +
      tip -
      (discount || 0) +
      (processingFeeAmt || 0) +
      (serviceFeeAmt || 0) +
      (addedDeliveryFee || 0) +
      fixedTips +
      (addedRoomServiceDeliveryFee || 0);

    // --------------------------------- food preparing timie ------------------------------
    // const { freeItemNames } =
    //   (businessInfo && businessInfo.orderPromotion) || {};

    const freeItemNames = this.state.freeItemNames_selected || [];

    const fullBHDetail = [];
    if (businessInfo) {
      const dayNames = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      for (let i = 0; i < dayNames.length; i++) {
        fullBHDetail.push({
          dayName: dayNames[i].charAt(0).toUpperCase() + dayNames[i].slice(1),
          hours: getBusinessHourStr(businessInfo.businessHour, dayNames[i]),
        });
      }
    }

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          margin: "0",
          padding: "0",
          backgroundColor: "white",
        }}
      >
        {/* ------------------------------  phone verification modal ----------------------------- */}
        {this.state.phoneVerificationModalShow ? (
          <Modal
            size="tiny"
            closeIcon
            onClose={this.handleVerificationModalClose}
            open={true}
          >
            <Segment basic>
              {!this.state.phoneVerificationCodeInputShow ? (
                <>
                  <span
                    style={{
                      fontSize: "20px",
                      margin: "10px 0",
                      fontWeight: "600",
                      display: "block",
                    }}
                  >
                    Verify phone number
                  </span>
                  <Input
                    type="text"
                    value={this.state.customerPhone || ""}
                    action
                    fluid
                  >
                    <Label
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      basic
                    >
                      +1
                    </Label>
                    <input
                      type="text"
                      pattern="[0-9]*"
                      onChange={(evt) => {
                        this.setState({
                          phoneVerificationErrorMsgShow: false,
                          phoneVerificationErrorMsg: "",
                        });
                        this.handleCustomerPhoneChange(evt.target.value);
                      }}
                    />
                    <Button
                      style={{ fontSize: "12px" }}
                      className="sumi-global-btn-color"
                      onClick={this.handleSendVerificationCodeClick}
                    >
                      {this.state.phoneVerificationCodeLoading ? (
                        <React.Fragment>
                          <Icon loading name="circle notch" />
                          Sending...
                        </React.Fragment>
                      ) : (
                        "Send Verification Code"
                      )}
                    </Button>
                  </Input>

                  {/* {this.state.phoneVerificationCodeLoading ? (
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              ) : null} */}
                </>
              ) : null}

              {this.state.phoneVerificationCodeInputShow ? (
                <>
                  <span
                    style={{
                      fontSize: "20px",
                      margin: "10px 0",
                      fontWeight: "600",
                      display: "block",
                    }}
                  >
                    <Icon name="send" /> Code sent!
                  </span>
                  <Input
                    type="text"
                    placeholder="Enter verification code here"
                    action
                    fluid
                  >
                    <input
                      type="number"
                      value={this.state.verificationCodeEntered || ""}
                      onChange={(evt) => {
                        this.handleVerificationCodeChange(evt.target.value);
                      }}
                    />
                    <Button
                      style={{ fontSize: "12px" }}
                      className="sumi-global-btn-color"
                      onClick={this.handleVerificationCodeEnterComplete}
                    >
                      Confirm
                    </Button>
                  </Input>
                </>
              ) : null}

              {this.state.phoneVerificationErrorMsgShow ? (
                <Message
                  attached="bottom"
                  negative
                  size="tiny"
                  style={{ marginTop: "10px" }}
                >
                  <Icon name="warning sign" />
                  {this.state.phoneVerificationErrorMsg}
                </Message>
              ) : null}
            </Segment>
          </Modal>
        ) : null}

        {/* ------------------------------  helmet header ----------------------------- */}
        <Helmet>
          <title>{(merchantName || "") + " on Luckory Online Ordering"}</title>
          <meta
            name="description"
            content={
              description || "Luckory Online Ordering - " + (merchantName || "")
            }
          />
          <link
            rel="canonical"
            href={`https://www.luckory.com/store/${merchantId}/${merchantName}`}
          />
        </Helmet>

        <Dimmer inverted active={this.state.loading} page>
          <Loader size="huge">{this.state.loadingMsg || ""}</Loader>
        </Dimmer>

        {/* ------------------------------  header panel ----------------------------- */}
        <Segment
          style={{
            height: "50px",
            top: "-1px",
            width: "100%",
            position: "fixed",
            padding: "5px",
            margin: "0",
            zIndex: "10",
          }}
        >
          <Image
            src={luckoryLogo}
            size="small"
            style={{
              // width: "40px",
              margin: "0px auto",
              display: "inline-block",
              left: "calc(50% - 68px)",
              verticalAlign: "0",
              position: "absolute",
              height: "25px",
              width: "auto",
              top: "12px",
              cursor: "pointer",
            }}
            // className="hide-if-medium noselect"
            className="noselect"
            onClick={this.handleLuckoryLogoClick}
          />

          {/* {this.props.userLoggedIn ? (
            <Header
              as="h3"
              className={
                this.state.curStep === this.STEPS.CHECKINGOUT
                  ? "checkoutUsderIcon"
                  : ""
              }
              style={{
                margin: "0",
                display: "inline",
                lineHeight: "40px",
                cursor: "pointer",
              }}
              onClick={this.handleUserDashboard}
            >
              {this.formatUsername(
                this.props.userInfo && this.props.userInfo.name
              )}
            </Header>
          ) : (
            <Button
              style={{ height: "40px" }}
              className={
                this.state.curStep === this.STEPS.CHECKINGOUT
                  ? "checkoutUsderIcon sumi-global-btn-color user-login"
                  : "sumi-global-btn-color user-login"
              }
              onClick={this.handleUserAuthIconClick}
            >
              Sign In
            </Button>
          )} */}

          {/* {this.state.curStep === this.STEPS.CHECKINGOUT ? null : (
            <Header
              as="h3"
              style={{
                margin: "0",
                display: "inline",
                lineHeight: "40px",
                cursor: "pointer",
                float: "left",
              }}
              onClick={() => {
                this.handleMerchantWebsiteClick(
                  businessInfo && businessInfo.webSite
                );
              }}
            >
              <Icon
                name="home"
                size="large"
                style={{ color: "rgb(0,49,98)" }}
              />
            
            </Header>
          )} */}

          <div
            style={{
              maxWidth: "300px",
              display: "inline-block",
              float: "left",
            }}
          >
            <Transition
              visible={this.state.curStep === this.STEPS.CHECKINGOUT}
              animation="fade right"
              duration={180}
              unmountOnHide={true}
            >
              <Header
                as="h3"
                style={{
                  margin: "0",
                  display: "inline",
                  lineHeight: "40px",
                  // marginLeft: "20px",
                  cursor: "pointer",
                }}
                onClick={this.handleBackToMenuClick}
              >
                <Icon name="arrow left" />
                <Header.Content className="backToMenuBtn">
                  Back to Menu
                </Header.Content>
              </Header>
            </Transition>
          </div>

          {isSmallDevice() &&
          this.state.curStep === this.STEPS.CHECKINGOUT ? null : (
            <Button
              className="sumi-global-btn-color shopping-cart-icon"
              onClick={this.handleShoppingCartIconClick}
            >
              <Icon name="in cart" style={{ fontSize: "18px" }} />
              {this.state.orderedItems &&
                countOrderItems({ orderItems: this.state.orderedItems })}
            </Button>
          )}
        </Segment>
        {/* ------------------------------  menu panel ----------------------------- */}
        <div className="menu-select-panel">
          {/* // ------------------------------ business notice ---------------------------- */}

          {this.state.curStep === this.STEPS.ORDERING &&
          businessInfo &&
          businessInfo.homePageNotice ? (
            <Modal
              size="tiny"
              closeIcon
              onClose={this.handleBusinessNoticeCloseClick}
              open={!!this.state.showBusinessNotice}
            >
              <Modal.Content>
                <Modal.Description>
                  {dineinTableName && dinein ? (
                    <React.Fragment>
                      <Header as="h1">Dear Customer:</Header>
                      <span style={{ display: "block", fontSize: "16px" }}>
                        Your Order will be placed on Table #{dineinTableName}
                        <br />
                        {businessInfo &&
                          (businessInfo.dineinNotice || "")
                            .split("<br/>")
                            .map((p, i) => <span key={i}>{p}</span>)}
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Header as="h1">Dear Customer:</Header>
                      {businessInfo &&
                        (businessInfo.homePageNotice || "")
                          .split("<br/>")
                          .map((p, i) => (
                            <span
                              key={i}
                              style={{ display: "block", fontSize: "16px" }}
                            >
                              {p}
                            </span>
                          ))}
                    </React.Fragment>
                  )}
                </Modal.Description>
              </Modal.Content>
              <div
                style={{
                  background: `#0e2a47 url(${noticeBgImg}) no-repeat 50%`,
                  backgroundSize: "cover",
                  width: "100%",
                  height: "80px",
                  display: "block",
                  color: "white",
                  textAlign: "center",
                  padding: "30px 0",
                  fontSize: "12px",
                }}
              >
                {/* <Image
                  src={noticeBgImg}
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "block",
                  }}
                /> */}
                All rights reserved. Supported by Luckory.
              </div>
            </Modal>
          ) : null}

          {this.state.curStep === this.STEPS.ORDERING ? (
            /* ------------------------------  order panel ----------------------------- */
            <Container textAlign="center">
              {/* ----------------------- business info ---------------------------------- */}
              <Segment
                // style={{
                //   // height: "150px",
                //   display: "flex",
                //   width: "100%",
                //   margin: "0",
                //   padding: "20px",
                //   // backgroundImage:
                //   //   "url('https://d1ralsognjng37.cloudfront.net/ce386752-0680-40c6-a876-f57e751cf520')",
                // }}
                className="homepage-businessinfo"
                basic
                textAlign="left"
              >
                {/* <Image
                  verticalAlign="middle"
                  bordered
                  rounded
                  src={require("../imgs/logo1.jpg")}
                  size="medium"
                  // floated="left"
                  style={{
                    // height: "calc(100%)",
                    height: "100px",
                    width: "auto",
                    // margin: "0 20px",
                  }}
                /> */}
                <div className="businessinfo">
                  {businessInfo && businessInfo.logoUrl ? (
                    <div className="logoImg">
                      <Image
                        src={businessInfo.logoUrl}
                        size="small"
                        onError={(i) => (i.target.style.display = "none")}
                      />
                    </div>
                  ) : null}

                  <h1 className="merchant-name">
                    {this.state.businessNameSelected}
                    {businessInfo && businessInfo.homePageNotice ? (
                      <Icon
                        name="bullhorn"
                        style={{
                          fontSize: "20px",
                          margin: "0 7px",
                          color: "#b58105",
                        }}
                        onClick={this.handleBusinessNoticeDisplayClick}
                      />
                    ) : null}
                  </h1>
                  <div>
                    <span className="merchant-info">
                      <Icon name="clock" />
                      {/* <Image
                        src={timeIcon}
                        size="tiny"
                        centered
                        style={{
                          width: "20px",
                          display: "inline",
                          margin: "0 5px",
                        }}
                      /> */}

                      {this.state.withinBusinessHour_today ? (
                        <React.Fragment>
                          <span
                            style={{
                              color: "green",
                              fontWeight: "600",
                            }}
                          >
                            Open
                            <span
                              style={{
                                color: "rgb(118, 118, 118)",
                                margin: "0 5px",
                              }}
                            >
                              ·
                            </span>
                          </span>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <span
                            style={{
                              color: "red",
                              fontWeight: "600",
                            }}
                          >
                            Closed
                            <span
                              style={{
                                color: "rgb(118, 118, 118)",
                                margin: "0 5px",
                              }}
                            >
                              ·
                            </span>
                          </span>
                        </React.Fragment>
                      )}

                      {foodPreparingTimeObj &&
                      foodPreparingTimeObj.status == "busy" ? (
                        <React.Fragment>
                          <span
                            style={{
                              color: "red",
                              // fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            Busy
                            <span
                              style={{
                                color: "rgb(118, 118, 118)",
                                margin: "0 5px",
                              }}
                            >
                              ·
                            </span>
                          </span>
                        </React.Fragment>
                      ) : foodPreparingTimeObj &&
                        foodPreparingTimeObj.status == "veryBusy" ? (
                        <React.Fragment>
                          <span
                            style={{
                              color: "#b50000de",
                              // fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            Very Busy
                            <span
                              style={{
                                color: "rgb(118, 118, 118)",
                                margin: "0 5px",
                              }}
                            >
                              ·
                            </span>
                          </span>
                        </React.Fragment>
                      ) : null}

                      {businessInfo &&
                        getBusinessHourStr(businessInfo.businessHour).join(
                          ", "
                        )}
                      <Popup
                        trigger={
                          <Icon
                            style={{ display: "inline", paddingLeft: "5px" }}
                            name="info circle"
                          ></Icon>
                        }
                        content={fullBHDetail.map(({ dayName, hours }, v) => {
                          return (
                            <div key={v}>
                              {dayName}: <b>{hours}</b>
                            </div>
                          );
                        })}
                        on="click"
                      />
                    </span>

                    <a
                      className="merchant-info"
                      // href={`http://maps.google.com/?q=${formatAddress(
                      //   this.state.businessInfoAddressSelected
                      // )}`}
                    >
                      <Icon
                        name="point"
                        // style={{
                        //   width: "20px",
                        //   display: "inline",
                        //   margin: "0 5px",
                        //   padding: "0 5px",
                        // }}
                      />
                      {/* <Image
                        src={locationIcon}
                        size="tiny"
                        centered
                        style={{
                          width: "20px",
                          display: "inline",
                          margin: "0 5px",
                        }}
                      /> */}

                      {formatAddressWithoutCountryName(
                        this.state.businessInfoAddressSelected
                      )}
                    </a>
                    <a
                      className="merchant-info"
                      // href={`tel:${businessInfo && businessInfo.phone}`}
                    >
                      <Icon name="phone square" />
                      {/* <Image
                        src={phoneIcon}
                        size="tiny"
                        centered
                        style={{
                          width: "20px",
                          display: "inline",
                          margin: "0 5px",
                        }}
                      /> */}

                      {businessInfo && formatPhoneNumber(businessInfo.phone)}
                    </a>

                    {!this.state.provideDelivery &&
                    !this.state.dineinTableName ? null : (
                      <div
                        className="orderMethod_select"
                        style={{ margin: "12px 0" }}
                      >
                        {!dineinTableName ? null : (
                          <Button
                            attached="left"
                            size="tiny"
                            className={
                              orderMethod == this.METHOD.DINEIN
                                ? "sumi-global-btn-color"
                                : ""
                            }
                            style={{
                              minWidth: "120px",
                              textAlign: "center",
                              padding: "12px",
                            }}
                            onClick={async () => {
                              await this.handleMethodChange(this.METHOD.DINEIN);
                            }}
                          >
                            Dinein - #{dineinTableName}
                          </Button>
                        )}

                        <Button
                          attached="left"
                          size="tiny"
                          className={
                            orderMethod == this.METHOD.PICKUP
                              ? "sumi-global-btn-color"
                              : ""
                          }
                          onClick={async () => {
                            await this.handleMethodChange(this.METHOD.PICKUP);
                            this.handleCustomerAddrChangeClick();
                            await this.handlePickupMethodClick();
                          }}
                          style={{
                            minWidth: "120px",
                            textAlign: "center",
                            padding: "12px",
                          }}
                        >
                          Pickup
                          {/* {foodPreparingTimeObj.fromMin_pickup ||
                          foodPreparingTimeObj.toMin_pickup ? (
                            <div style={{ fontSize: "12px" }}>
                              <span>
                                {foodPreparingTimeObj.fromMin_pickup ||
                                  foodPreparingTimeObj.toMin_pickup}
                                -
                                {foodPreparingTimeObj.toMin_pickup ||
                                  foodPreparingTimeObj.fromMin_pickup}{" "}
                                mins
                              </span>
                            </div>
                          ) : null} */}
                        </Button>

                        {this.state.provideDelivery ? (
                          <Button
                            attached="right"
                            size="tiny"
                            className={
                              orderMethod == this.METHOD.DELIVERY
                                ? "sumi-global-btn-color"
                                : ""
                            }
                            onClick={this.handleDeliveryMethodClick}
                            style={{
                              minWidth: "120px",
                              textAlign: "center",
                              padding: "12px",
                            }}
                            disabled={
                              this.state.deliveryAvailableTime &&
                              !checkIfWithinDeliveryHour(
                                this.state.deliveryAvailableTime
                              )
                            }
                          >
                            Delivery
                            {/* {(foodPreparingTimeObj.fromMin_delivery ||
                              foodPreparingTimeObj.toMin_delivery) &&
                            businessInfo &&
                            businessInfo.delivery ? (
                              <div style={{ fontSize: "12px" }}>
                                <span>
                                  {foodPreparingTimeObj.fromMin_delivery ||
                                    foodPreparingTimeObj.toMin_delivery}
                                  -
                                  {foodPreparingTimeObj.toMin_delivery ||
                                    foodPreparingTimeObj.fromMin_delivery}{" "}
                                  mins
                                </span>
                              </div>
                            ) : null} */}
                          </Button>
                        ) : null}
                      </div>
                    )}

                    {!enableFoodTimeSchedule ? null : (
                      <div
                        className="orderMethod_select"
                        style={{ margin: "12px 0" }}
                      >
                        <Button
                          attached="left"
                          size="tiny"
                          className={
                            !this.state.scheduleTimeSelected
                              ? "sumi-global-btn-color"
                              : ""
                          }
                          onClick={() => this.handleAsapOptionSelect()}
                          style={{
                            minWidth: "120px",
                            textAlign: "center",
                            padding: "10px",
                          }}
                        >
                          <div style={{ display: "inline" }}>ASAP</div>
                          {(this.state.orderMethod === this.METHOD.PICKUP ||
                            this.state.orderMethod === this.METHOD.CURBSIDE) &&
                          foodPreparingTimeObj &&
                          (foodPreparingTimeObj.fromMin_pickup ||
                            foodPreparingTimeObj.toMin_pickup) ? (
                            <div style={{ display: "inline" }}>
                              <span
                                style={{
                                  margin: "0 5px",
                                }}
                              >
                                ·
                              </span>
                              <span>
                                {foodPreparingTimeObj.fromMin_pickup ||
                                  foodPreparingTimeObj.toMin_pickup}
                                -
                                {foodPreparingTimeObj.toMin_pickup ||
                                  foodPreparingTimeObj.fromMin_pickup}{" "}
                                mins
                              </span>
                            </div>
                          ) : null}

                          {this.state.orderMethod === this.METHOD.DELIVERY &&
                          foodPreparingTimeObj &&
                          (foodPreparingTimeObj.fromMin_delivery ||
                            foodPreparingTimeObj.toMin_delivery) ? (
                            <div style={{ display: "inline" }}>
                              <span
                                style={{
                                  margin: "0 5px",
                                }}
                              >
                                ·
                              </span>
                              <span>
                                {foodPreparingTimeObj.fromMin_delivery ||
                                  foodPreparingTimeObj.toMin_delivery}
                                -
                                {foodPreparingTimeObj.toMin_delivery ||
                                  foodPreparingTimeObj.fromMin_delivery}{" "}
                                mins
                              </span>
                            </div>
                          ) : null}
                        </Button>
                        <Button
                          attached="left"
                          size="tiny"
                          className={
                            this.state.scheduleTimeSelected
                              ? "sumi-global-btn-color"
                              : ""
                          }
                          onClick={() => this.handleScheduleDateClick()}
                          style={{
                            minWidth: "120px",
                            textAlign: "center",
                            padding: "10px",
                          }}
                        >
                          <div style={{ display: "inline" }}>Schedule</div>
                          {this.state.scheduleDate ? (
                            <div style={{ display: "inline" }}>
                              <span
                                style={{
                                  margin: "0 5px",
                                }}
                              >
                                ·
                              </span>
                              {formatDateMonthDay(this.state.scheduleDate)} @
                              {this.state.scheduleTimeStr}
                            </div>
                          ) : null}
                        </Button>
                      </div>
                    )}

                    {orderMethod == this.METHOD.DELIVERY &&
                    this.state.customerAddressStr ? (
                      <div className="merchant-info">
                        <span style={{ backgroundColor: "yellow" }}>
                          This order will be deliveried to{" "}
                          <span style={{ fontSize: "15px", color: "black" }}>
                            {this.state.customerAddressStr}
                          </span>
                        </span>
                        <span
                          style={{
                            color: "red",
                            fontWeight: "600",
                            margin: "0 10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.setState({
                              showDeliveryAddrModal: true,
                            });
                            this.handleCustomerAddrChangeClick();
                          }}
                        >
                          Change
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Segment>

              {/* <Message
                color="red"
                hidden={this.state.withinBusinessHour}
                icon="coffee"
                header="Closed"
              /> */}

              {/* {this.state.withinBusinessHour ? null : (
                <div
                  className="ui red icon message"
                  style={{ display: "block" }}
                >
                  <i
                    ariaHidden="true"
                    className="coffee icon"
                    style={{ margin: "0" }}
                  ></i>
                  <div className="content">
                    <div className="header">Store Closed</div>
                  </div>
                </div>
              )} */}

              {/* -------------------------- category list for small device ------------------------------ */}
              {this.state.curStep === this.STEPS.CHECKINGOUT ? null : (
                <React.Fragment>
                  <CategoryListScrollbar
                    showLeftScrollingSign={this.state.showLeftScrollingSign}
                    showRightScrollingSign={this.state.showRightScrollingSign}
                    onCategoryListScroll={this.handleCategoryListScroll}
                    onCategoryChange={this.handleCategoryChange}
                    onModalClick={this.handleCategoryModalClick}
                    category_selected={category_selected}
                    categories={this.state.categories}
                  />
                  {/* <Divider style={{ margin: "2px 0", padding: "0" }} /> */}
                </React.Fragment>
              )}

              {!!this.state.showScrollModal ? (
                <div
                  className="shopping-cart-panel-overlay"
                  onClick={(evt) => {
                    this.setState({
                      showScrollModal: false,
                    });
                  }}
                ></div>
              ) : null}
              <Transition
                visible={!!this.state.showScrollModal}
                animation="fade left"
                duration={180}
                style={{ width: "225px !important;" }}
              >
                <Segment className="category-list-panel" textAlign="left">
                  <CategoryListScrollbarModal
                    onCategoryListScroll={this.handleCategoryListScroll}
                    onCategoryChange={this.handleCategoryChange}
                    category_selected={category_selected}
                    categories={this.state.categories}
                  />
                </Segment>
              </Transition>

              <div style={{ display: "flex", margin: "15px 0" }}>
                <CategoryListPanel
                  categories={this.state.categories}
                  category_selected={this.state.category_selected}
                  onCategoryClick={this.handleCategoryChange}
                />

                <ItemListPanel
                  loading={this.state.loadingItems}
                  itemsGroupbyCategory={this.state.itemsGroupbyCategory}
                  merchantId={this.state.merchantId}
                  category={this.state.category_selected}
                  items={this.state.items}
                  item_selected={this.state.item_selected}
                  itemOptions={this.state.itemOptions}
                  toogleList={this.state.toogleList}
                  itemSizes={this.state.itemSizes}
                  itemQty={this.state.itemQty}
                  itemNote={this.state.itemNote}
                  // itemOptionVals_selected={this.state.itemOptionVals_selected}
                  itemOption_selected={this.state.itemOption_selected}
                  itemSize_selected={this.state.itemSize_selected}
                  offBusinessHour={!this.state.withinBusinessHour}
                  onItemClick={this.handleItemClick}
                  onAddToCartClick={this.handleAddToCartClick}
                  onItemModalClose={this.handleItemModalClose}
                  onItemQtyChange={this.handleItemQtyChange}
                  onItemNoteChange={this.handleItemNoteChange}
                  onItemOptionChange={this.handleItemOptionChange}
                  onItemOptionIncreaseClick={this.handleItemOptionIncreaseClick}
                  onItemOptionDecreaseClick={this.handleItemOptionDecreaseClick}
                  onItemSizeChange={this.handleItemSizeChange}
                  businessInfo={this.state.businessInfo}
                  onToggleListClick={this.handleToggleListClick}
                  scheduleDate={this.state.scheduleDate}
                />
              </div>
            </Container>
          ) : (
            /* // ---------------------------- order summary ---------------------------------- */
            <div style={{ overflowX: "hidden" }}>
              <Transition
                visible={this.state.orderSummaryErrorMsgShow}
                // visible={true}
                animation="fade left"
                duration={500}
              >
                <Message
                  error
                  floating
                  onDismiss={this.hideOrderSummaryErrorMsg}
                  header={this.state.orderSummaryErrorMsg_head}
                  content={this.state.orderSummaryErrorMsg_body}
                  className="orderSummeryErrorMsg"
                />
              </Transition>

              <Container textAlign="center">
                <OrderSummaryPanel
                  needCustomerAddrMsgShow={this.state.needCustomerAddrMsgShow}
                  needCustomerInfoMsgShow={this.state.needCustomerInfoMsgShow}
                  needCardInfoMsgShow={this.state.needCardInfoMsgShow}
                  needCarInfoMsgShow={this.state.needCarInfoMsgShow}
                  // notAboveMinOrderForDeliveryShow={
                  //   this.state.notAboveMinOrderForDeliveryShow
                  // }
                  minOrderForDelivery={this.state.minOrderForDelivery}
                  lng={this.state.lng}
                  lat={this.state.lat}
                  scheduleTimeStr={this.state.scheduleTimeStr}
                  scheduleDate={this.state.scheduleDate}
                  businessInfo={this.state.businessInfo}
                  timeSchedualList={this.state.timeSchedualList}
                  xDayBusinessHours={this.state.xDayBusinessHours}
                  withinBusinessHour_today={this.state.withinBusinessHour_today}
                  customerAddressStr={this.state.customerAddressStr}
                  deliveryOutRangeWarning={this.state.deliveryOutRangeWarning}
                  billingAddressStr={this.state.billingAddressStr}
                  billingZipCode={this.state.billingZipCode}
                  orderMethod={this.state.orderMethod}
                  dineinTableName={this.state.dineinTableName}
                  METHOD={this.METHOD}
                  paymentMethod={this.state.paymentMethod}
                  PAYMENT_METHOD={this.PAYMENT_METHOD}
                  deliveryNote={this.state.deliveryNote}
                  orderedItems={this.state.orderedItems}
                  // orderedItems_PromoMethod={this.state.orderedItems_PromoMethod}
                  customerPhone={this.state.customerPhone}
                  customerEmail={this.state.customerEmail}
                  customerName={this.state.customerName}
                  cardNumber={this.state.cardNumber}
                  cardCVV={this.state.cardCVV}
                  cardExpiration={this.state.cardExpiration}
                  carMakeModel={this.state.carMakeModel}
                  carColor={this.state.carColor}
                  receiveOrderInfo={this.state.receiveOrderInfo}
                  onRemoveFromCartClick={this.handleRemoveFromCartClick}
                  onMethodChange={this.handleMethodChange}
                  onPaymentMethodChange={this.handlePaymentMethodChange}
                  onCustomerAddressSubmit={this.handleCustomerAddressSubmit}
                  onCustomerAddrChangeClick={this.handleCustomerAddrChangeClick}
                  onDeliveryNoteChange={this.handleDeliveryNoteChange}
                  onCustomerNameChange={this.handleCustomerNameChange}
                  onCustomerPhoneChange={this.handleCustomerPhoneChange}
                  onCustomerEmailChange={this.handleCustomerEmailChange}
                  onCardNumberChange={this.handleCardNumberChange}
                  onCardExpirationChange={this.handleCardExpirationChange}
                  onCardCVVChange={this.handleCardCVVChange}
                  onBillAddressChange={this.handleBillAddressChange}
                  onBillZipCodeChange={this.handleBillZipCodeChange}
                  onSameAsDeliveryAddrClick={this.handleSameAsDeliveryAddrClick}
                  onReceiveOrderInfoClick={this.handleReceiveOrderInfoClick}
                  onCarMakeModelChange={this.handleCarMakeModelChange}
                  onCarColorChange={this.handleCarColorChange}
                  onOrderDateSelect={this.handleOrderDateSelect}
                  // -------- below is used only for small device ---------------------
                  promoCode={this.state.promoCode}
                  promoCodeApplied={this.state.promoCodeApplied}
                  subtotal={subtotal}
                  tax={tax}
                  tip={tip}
                  fixedTips={fixedTips}
                  fixedTipRate={fixedTipRate}
                  discount={discount}
                  processingFeeAmt={processingFeeAmt}
                  serviceFeeAmt={serviceFeeAmt}
                  deliveryFee={addedDeliveryFee}
                  roomServiceDeliveryFee={addedRoomServiceDeliveryFee}
                  total={total}
                  order_tips_pct={this.state.order_tips_pct}
                  custom_tips={this.state.custom_tips}
                  onOrderTipChange={this.handleOrderTipChange}
                  onOrderCustomTipClick={this.handleOrderCustomTipClick}
                  onCustomTipChange={this.handleCustomTipChange}
                  onPromoCodeChange={this.handlePromoCodeChange}
                  onPromoCodeApplyClick={this.handlePromoCodeApplyClick}
                  onPromoCodeRemove={this.handlePromoCodeRemove}
                  onBackToMenuClick={this.handleBackToMenuClick}
                  onPlaceOrderClick={this.handlePlaceOrderClick}
                  onHideNeedCardInfoMsg={this.handleHideNeedCardInfoMsg}
                  //--------------------------------- time schedule ----------------------------
                  scheduleTimeSelected={this.state.scheduleTimeSelected}
                  onScheduleTimeOptionSelect={this.handleScheduleDateClick}
                  onAsapOptionSelect={this.handleAsapOptionSelect}
                  onScheduleDateSelect={this.handleScheduleDateSelect}
                  onScheduledTimeChange={this.handleScheduledTimeChange}
                  // ----------------------------------- order promotion free add-on -----------
                  freeItemNameSelected={this.state.freeItemNameSelected}
                  freeItemNames={this.state.freeItemNames_selected}
                  minAmount={this.state.amount_selected}
                  onOrderPromotionItemSelect={
                    this.handleOrderPromotionItemSelect
                  }
                  orderPromotionAvailableTime={orderPromotionAvailableTime}
                  // ------------------------------ room service --------------------------------
                  onRoomServiceRoomNumberChange={
                    this.handleRoomServiceRoomNumberChange
                  }
                  roomServiceRoom={roomServiceRoom}
                  isBranch={!!this.state.isBranchSelected}
                />
              </Container>
            </div>
          )}
          {/* ------------------------------  footer panel ----------------------------- */}
          <Divider className="no-margin" />
          <Segment
            basic
            style={{
              margin: "0",
              color: "rbg(0,49,98)",
              fontSize: "11px",
              fontWeight: "600",
            }}
          >
            <a href="/document/terms-of-service" style={{ margin: "0 10px" }}>
              Terms of Service
            </a>

            <a href="/document/privacy-policy" style={{ margin: "0 10px" }}>
              Privacy
            </a>
            <span style={{ margin: "0 10px" }}>© 2025 Luckory</span>
          </Segment>
        </div>
        {/* ------------------------------  cart panel ----------------------------- */}
        {/* showShoppingCart */}
        {!!this.state.showShoppingCart ? (
          <div
            className="shopping-cart-panel-overlay"
            onClick={(evt) => {
              this.setState({
                showShoppingCart: false,
              });
            }}
          ></div>
        ) : null}
        <Transition
          visible={!!this.state.showShoppingCart}
          animation="fade left"
          duration={180}
        >
          <Segment className="shopping-cart-panel" textAlign="left">
            <ShoppingCartPanel
              merchantId={this.state.merchantId}
              promoCode={this.state.promoCode}
              promoCodeApplied={this.state.promoCodeApplied}
              orderMethod={this.state.orderMethod}
              METHOD={this.METHOD}
              paymentMethod={this.state.paymentMethod}
              PAYMENT_METHOD={this.PAYMENT_METHOD}
              subtotal={subtotal}
              tax={tax}
              tip={tip}
              fixedTips={fixedTips}
              fixedTipRate={fixedTipRate}
              discount={discount}
              processingFeeAmt={processingFeeAmt}
              serviceFeeAmt={serviceFeeAmt}
              deliveryFee={addedDeliveryFee}
              roomServiceDeliveryFee={addedRoomServiceDeliveryFee}
              total={total}
              customerPhone={customerPhone}
              order_tips_pct={this.state.order_tips_pct}
              custom_tips={this.state.custom_tips}
              orderedItems={this.state.orderedItems}
              // orderedItems_PromoMethod={this.state.orderedItems_PromoMethod}
              onRemoveFromCartClick={this.handleRemoveFromCartClick}
              onRemoveUnavailableItems={this.handleRemoveUnavailableItems}
              onCheckoutClick={this.handleCheckoutClick}
              offBusinessHour={!this.state.withinBusinessHour}
              onGoOrderingClick={this.handleGoOrderingClick}
              onBackToMenuClick={this.handleBackToMenuClick}
              onOrderTipChange={this.handleOrderTipChange}
              onOrderCustomTipClick={this.handleOrderCustomTipClick}
              onCustomTipChange={this.handleCustomTipChange}
              isCheckingout={this.state.curStep === this.STEPS.CHECKINGOUT}
              onPlaceOrderClick={this.handlePlaceOrderClick}
              onCustomerPhoneChange={this.handleCustomerPhoneChange}
              onCloseIconClick={this.handleShoppingCartCloseIconClick}
              onPromoCodeChange={this.handlePromoCodeChange}
              onPromoCodeApplyClick={this.handlePromoCodeApplyClick}
              onPromoCodeRemove={this.handlePromoCodeRemove}
              scheduleDate={this.state.scheduleDate}
            />
          </Segment>
        </Transition>
        {/* ------------------------------  delivery or pickup select modal ----------------------------- */}
        <Modal
          dimmer={true}
          open={!!this.state.showOrderMethodSelectModal}
          // onClose={this.handleOrderMethodModalClose}
          // className="goorder-online-itemDetail"
        >
          <Segment basic>
            {this.state.withinBusinessHour ? null : (
              <span
                style={{
                  fontSize: "14px",
                  display: "block",
                  backgroundColor: "red",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Store is closed now
              </span>
            )}
            <span
              style={{
                fontSize: "20px",
                margin: "10px 0",
                fontWeight: "600",
                display: "block",
              }}
            >
              Select your order method
            </span>
            <span style={{ display: "block", color: "#6d6d6d" }}>
              <Icon name="info circle" />
              We are gladly providing delivery service for the customers within
              certain distance.
            </span>
          </Segment>

          <Segment style={{ margin: "0" }}>
            <Grid columns={2} textAlign="center">
              <Divider vertical>Or</Divider>
              <Grid.Row verticalAlign="middle">
                <Grid.Column>
                  <Button
                    className="orderMethodSelectBtn"
                    onClick={async () => {
                      await this.handleMethodChange(this.METHOD.PICKUP);
                      this.handleCustomerAddrChangeClick();
                      await this.handlePickupMethodClick();
                    }}
                    // disabled={!this.state.withinBusinessHour}
                  >
                    <Image src={pickupIcon} size="tiny" centered />
                    <br />
                    <div
                      style={{
                        fontSize: "20px",
                        color: "rgb(0, 49,98)",
                        marginBottom: "5px",
                      }}
                    >
                      Pickup
                    </div>

                    {foodPreparingTimeObj &&
                    (foodPreparingTimeObj.fromMin_pickup ||
                      foodPreparingTimeObj.toMin_pickup) ? (
                      <div>
                        <span>
                          {foodPreparingTimeObj.fromMin_pickup ||
                            foodPreparingTimeObj.toMin_pickup}
                          -
                          {foodPreparingTimeObj.toMin_pickup ||
                            foodPreparingTimeObj.fromMin_pickup}{" "}
                          mins
                        </span>
                      </div>
                    ) : null}
                  </Button>
                </Grid.Column>

                <Grid.Column>
                  <Button
                    className="orderMethodSelectBtn"
                    onClick={this.handleDeliveryMethodClick}
                    // disabled={!this.state.withinBusinessHour}
                    disabled={
                      this.state.deliveryAvailableTime &&
                      !checkIfWithinDeliveryHour(
                        this.state.deliveryAvailableTime
                      )
                    }
                  >
                    <Image src={deliveryIcon} size="tiny" centered />
                    <br />
                    <div
                      style={{
                        fontSize: "20px",
                        color: "rgb(0, 49,98)",
                        marginBottom: "5px",
                      }}
                    >
                      Delivery
                    </div>

                    {this.state.deliveryAvailableTime &&
                    !checkIfWithinDeliveryHour(
                      this.state.deliveryAvailableTime
                    ) ? (
                      <span
                        style={{ fontSize: "x-small", display: "inline-block" }}
                      >
                        {secondsToTimeStr(
                          this.state.deliveryAvailableTime.open
                        )}{" "}
                        -{" "}
                        {secondsToTimeStr(
                          this.state.deliveryAvailableTime.close
                        )}
                      </span>
                    ) : foodPreparingTimeObj &&
                      (foodPreparingTimeObj.fromMin_delivery ||
                        foodPreparingTimeObj.toMin_delivery) ? (
                      <div>
                        <span>
                          {foodPreparingTimeObj.fromMin_delivery ||
                            foodPreparingTimeObj.toMin_delivery}
                          -
                          {foodPreparingTimeObj.toMin_delivery ||
                            foodPreparingTimeObj.fromMin_delivery}{" "}
                          mins
                        </span>
                      </div>
                    ) : null}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Modal>
        {/* ----------------- customer address enter if customer select delivery ------------------ */}
        <Modal
          // onClose={this.handleDeliveryAddrModalClose}
          open={!!this.state.showDeliveryAddrModal}
          size="tiny"
        >
          <Modal.Content>
            <Dimmer inverted active={this.state.loading_googleMap}>
              <Loader size="huge" />
            </Dimmer>

            {this.state.customerAddressStr ? (
              <React.Fragment>
                <div style={{ backgroundColor: "yellow" }}>
                  <Icon name="info circle" size="small" />
                  Delivery Fee is{" "}
                  <div
                    style={{
                      fontSize: "larger",
                      fontWeight: "600",
                      display: "inline-block",
                    }}
                  >
                    ${deliveryFee || 0}
                  </div>{" "}
                  upon your location
                </div>

                {foodPreparingTimeObj &&
                (foodPreparingTimeObj.fromMin_delivery ||
                  foodPreparingTimeObj.toMin_delivery) ? (
                  <div style={{ backgroundColor: "yellow" }}>
                    <Icon name="clock" size="small" />
                    Estimated time is{" "}
                    <div
                      style={{
                        fontSize: "larger",
                        fontWeight: "600",
                        display: "inline-block",
                      }}
                    >
                      {foodPreparingTimeObj.fromMin_delivery ||
                        foodPreparingTimeObj.toMin_delivery}
                      -
                      {foodPreparingTimeObj.toMin_delivery ||
                        foodPreparingTimeObj.fromMin_delivery}{" "}
                      mins
                    </div>{" "}
                  </div>
                ) : null}

                {minOrderForDelivery ? (
                  <p style={{ backgroundColor: "yellow" }}>
                    <Icon name="info circle" size="small" />
                    Minimum order amount for Delivery is $
                    <div
                      style={{
                        fontSize: "larger",
                        fontWeight: "600",
                        display: "inline-block",
                      }}
                    >
                      {minOrderForDelivery || 0}
                    </div>{" "}
                    or above
                  </p>
                ) : null}

                <Header as="h5">Delivery Address</Header>
                <Header as="h3">{this.state.customerAddressStr}</Header>
              </React.Fragment>
            ) : null}

            <GoogleMapPanel
              width={"100%"}
              maxHeight={"150px"}
              lat={this.state.lat}
              lng={this.state.lng}
              needCustomerAddrMsgShow={this.state.needCustomerAddrMsgShow}
              deliveryOutRangeWarning={this.state.deliveryOutRangeWarning}
              onCustomerAddressSelected={this.handleDeliveryAddrSelected}
            />

            {this.state.customerAddressStr ? (
              <React.Fragment>
                <Divider />
                <Form style={{ margin: "0" }}>
                  <Header as="h5">Note / Apt# / Access Code:</Header>
                  <TextArea
                    rows={2}
                    placeholder="e.g. Ring the bell after dropoff, call upon arrival / Apt#1234 / Access Code:1234, etc."
                    value={this.state.deliveryNote || ""}
                    onChange={(evt) =>
                      this.handleDeliveryNoteChange(evt.target.value)
                    }
                  />
                </Form>
              </React.Fragment>
            ) : null}
          </Modal.Content>

          <Modal.Actions>
            <Button
              content="Cancel"
              onClick={this.handleDeliveryAddrBackClick}
            />
            <Button
              content="Done"
              className="sumi-global-btn-color"
              onClick={this.handleDeliveryAddrDoneClick}
            />
          </Modal.Actions>
        </Modal>

        {/* ------------------------------ branch select ------------------------------------- */}
        <Modal
          open={!!this.state.showBusinessInfoAddressSelectModal}
          size="tiny"
        >
          <Segment basic style={{ margin: "0" }}>
            <span
              style={{
                fontSize: "20px",
                margin: "10px 0",
                fontWeight: "600",
                display: "block",
              }}
            >
              Select Store Location
            </span>
          </Segment>

          <Segment style={{ margin: "0", textAlign: "center" }}>
            <Button
              style={{
                backgroundColor: "white",
                boxShadow:
                  "0 0 0 1px #d4d4d5, 0 2px 4px 0 rgba(34, 36, 38, 0.12),0 2px 10px 0 rgba(34, 36, 38, 0.15)",
                width: "100%",
                lineHeight: "1.5",
              }}
              onClick={() => {
                this.handleBranchSelect();
              }}
              // disabled={!this.state.withinBusinessHour}
            >
              <span
                style={{
                  display: "block",
                  fontSize: "x-large",
                  color: "rgb(0, 49,98)",
                  fontWeight: "bold",
                }}
              >
                {this.state.businessName}
              </span>
              <span
                style={{
                  display: "block",
                  fontSize: "medium",
                  color: "#767676",
                }}
              >
                {this.state.businessAddress &&
                  this.state.businessAddress.street}
              </span>
              <span
                style={{
                  display: "block",
                  fontSize: "medium",
                  color: "#767676",
                }}
              >
                {this.state.businessAddress && this.state.businessAddress.city},{" "}
                {this.state.businessAddress && this.state.businessAddress.state}{" "}
                {this.state.businessAddress &&
                  this.state.businessAddress.postalCode}
              </span>
            </Button>
            <Divider />
            <Button
              style={{
                backgroundColor: "white",
                boxShadow:
                  "0 0 0 1px #d4d4d5, 0 2px 4px 0 rgba(34, 36, 38, 0.12),0 2px 10px 0 rgba(34, 36, 38, 0.15)",
                width: "100%",
                lineHeight: "1.5",
              }}
              onClick={() => {
                this.handleBranchSelect(true);
              }}
              // disabled={!this.state.withinBusinessHour}
            >
              <span
                style={{
                  display: "block",
                  fontSize: "x-large",
                  color: "rgb(0, 49,98)",
                  fontWeight: "bold",
                }}
              >
                {this.state.branchName}
              </span>
              <span
                style={{
                  display: "block",
                  fontSize: "medium",
                  color: "#767676",
                }}
              >
                {this.state.branchAddress && this.state.branchAddress.street}
              </span>
              <span
                style={{
                  display: "block",
                  fontSize: "medium",
                  color: "#767676",
                }}
              >
                {this.state.branchAddress && this.state.branchAddress.city},{" "}
                {this.state.branchAddress && this.state.branchAddress.state}{" "}
                {this.state.branchAddress &&
                  this.state.branchAddress.postalCode}
              </span>
            </Button>
          </Segment>
        </Modal>

        {/* ------------------------------ User auth & dashboard ------------------------------------- */}
        <Modal
          dimmer={true}
          open={!!this.state.showUserAuth && !this.props.userLoggedIn}
          style={{ background: "none", boxShadow: "none" }}
        >
          <div className="login-modal-div" style={{ display: "block" }}>
            <div className="modal-content">
              <div className="close-div">
                Please Login To Continue
                <span
                  className="close"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.setState({ showUserAuth: false })}
                >
                  ×
                </span>
              </div>

              <UserAuthModal
                errMsg={this.props.errMsg}
                onSigninClick={this.handleSigninClick}
                userInfo={this.props.userInfo}
              />
            </div>
          </div>
        </Modal>

        <Modal
          dimmer={true}
          open={!!this.state.showUserDashboard}
          style={{ boxShadow: "none", maxWidth: "600px" }}
          className="userInfoTab"
        >
          <UserDashboardModal
            handleDashboardClose={this.handleDashboardClose}
            onLogoutClick={this.handleLogoutClick}
            userInfo={this.props.userInfo}
            handleUserInfoUpdate={this.handleUserInfoUpdate}
            userDashboardErrMsg={this.props.userDashboardErrMsg}
            clearUserDashboardMsg={this.clearUserDashboardMsg}
            userDashboardMessage={this.props.userDashboardMessage}
          />
        </Modal>

        {/* -------------------------------- order scedule time ----------------------------- */}
        <Modal
          dimmer={true}
          open={this.state.scheduledTimeSelectOpen}
          style={{ minHeight: "32rem", maxWidth: "500px" }}
          onClose={this.handleScheduleDateSelectModalClose}
        >
          <div className="time">
            <Segment basic>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                Please select a date
              </div>
              <Button.Group fluid basic style={{ margin: "10px 0 0 0" }}>
                {(this.state.xDayBusinessHours || []).map((hourinfo, index) => {
                  return (
                    <Button
                      key={hourinfo._id}
                      onClick={() => {
                        this.handleOrderDateSelect(hourinfo);
                      }}
                      disabled={hourinfo.disable}
                      style={{
                        width: "33.33%",
                        border:
                          this.state.scheduleDate &&
                          this.state.scheduleDate.getDate() ==
                            hourinfo.date.getDate()
                            ? "2px solid rgb(0, 49, 98)"
                            : "unset",
                        padding: "5px",
                      }}
                    >
                      <span
                        style={{
                          display: "block",
                          fontSize: "16px",
                          marginBottom: "5px",
                          color: "#4e4e4e",
                          fontWeight: "700",
                        }}
                      >
                        {index == 0
                          ? "Today"
                          : hourinfo.dayname[0].toUpperCase() +
                            hourinfo.dayname.slice(1).toLowerCase()}
                      </span>
                      <span
                        style={{
                          display: "block",
                          fontSize: "12px",
                          color: "#4e4e4e",
                        }}
                      >
                        {hourinfo.datestr}
                      </span>
                    </Button>
                  );
                })}
              </Button.Group>
            </Segment>
          </div>
          <Segment basic style={{ marginTop: "0" }}>
            {this.state.timeSchedualList.length == 0 ? (
              <div style={{ color: "red", paddingBottom: "10px" }}>
                No available time to schedule, please choose another date!
              </div>
            ) : null}

            <div
              style={{
                fontSize: "18px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Select a{" "}
              {orderMethod === this.METHOD.DELIVERY ? "delivery" : "pickup"}{" "}
              time
            </div>
            <Select
              open={this.state.scheduledTimeSelectOpen}
              style={{ width: "100%", backgroundColor: "yellow" }}
              options={this.state.timeSchedualList.map((e, index) => {
                return {
                  key: e,
                  text: getTimeStringFromMin(e),
                  value: e,
                };
              })}
              onChange={(evt, data) => {
                this.handleScheduledTimeChange(data.value);
              }}
              // onClick={() => {
              //   console.log(222);

              //   if (this.state.scheduleTimeStr) {
              //     this.setState({
              //       scheduledTimeSelectOpen:
              //         !this.state.scheduledTimeSelectOpen,
              //     });
              //   }
              // }}
            />
          </Segment>
        </Modal>
      </div>
    );
  }
}

export default HomePanelPanel;
